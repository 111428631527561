import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Card, Avatar, Divider, Modal, ListItem, List, IconButton, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import Header from "./HomeHeader";
import { COLORS } from "../constants/Theme";
import AppointmentModal from "./AppointmentBook";
import RequestModal from "./Request";
import { IoLanguageOutline } from "react-icons/io5";
import { LuGraduationCap } from "react-icons/lu";
import { useLocation, useHistory } from "react-router-dom";
import { isResponseIsValid, snackBar } from '../utils/helpers';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from "react-redux";
import { getProfileApi } from '../utils/apiCalls';
import { profileImageSaved } from '../store/actions/authActions';
import {
  upcomingEvents,
  updateBooking,
  specializationListWithUnitID,
  doctorsListInConsultation,
} from '../utils/apiCalls';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import Images from "../constants/Images";
import TermsAndConditionsModal from "./TermsConditionsModal";
import PrivacyPolicy from "./PrivacyPolicy1";
import LoginDrawer from "./LoginDrawer";

const doctorsData = [
  {
    name: "Dr. Sathish P",
    qualifications: "MS, DNB, MCh",
    specialty: "Surgical Gastroenterology",
    experience: "10+ years experience",
    languages: "Tamil, English",
    rating: 4.8,
    nextAvailable: "Today, 2:00 PM",
    education: ["MBBS - Madurai Medical College", "MS - Madras Medical College"],
    specialization:
      "Expertise in minimally invasive surgical procedures for gastrointestinal disorders.",
    fee: "800"
  },
  {
    name: "Dr. Mala Balagopal R",
    qualifications: "MBBS, DO",
    specialty: "Ophthalmology",
    experience: "10+ years experience",
    languages: "Tamil, English",
    rating: 4.8,
    nextAvailable: "Today, 2:00 PM",
    education: ["MBBS - Madurai Medical College", "D.O - RIOGOH, Madras Medical College"],
    specialization:
      "Presented papers on contact lens fitting and conducted instruction courses at various conferences.",
    fee: "800"
  },
];

const DoctorCard = ({ doctor, unitAddress, unitId, setDoctor, setOpenPopup, openPopup, onOpenAppointmentModal, onOpenRequestModal, onClick }) => (
  <Card
    sx={{
      p: 2,
      mb: 3,
      boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
      borderRadius: "12px",
      cursor: "pointer"
    }}
    onClick={onClick}
  >
    {/* Top Section */}
    <Box display="flex" alignItems="flex-start" gap={2}>
      {/* Image */}
      <Avatar
        variant="square"
        sx={{
          width: 120,
          height: 140,
          borderRadius: "8px",
          backgroundColor: "#f8f8f8",
        }}
        src={doctor.photo ? doctor.photo : doctor.gender === 'Male' ? Images.doctorMaleEmpty : doctor.gender === 'Female' ? Images.doctorFemaleEmpty : Images.profileEmptyImage}
      >

      </Avatar>
      {/* Doctor Details */}
      <Box sx={{ display: 'flex', flexDirection: "column" }}>
        <Typography variant="h7" sx={{ fontWeight: "bold", color: COLORS.textColor, fontFamily: 'Poppins' }}>
          {doctor.first_name}
        </Typography>
        <Typography variant="body4" sx={{ color: COLORS.textColor, fontFamily: "Poppins", fontSize: "14px" }}>
          {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
        </Typography>
        <Box
          sx={{
            display: "inline-flex",  // Shrink-wrap around the text
            alignSelf: "flex-start",
            backgroundColor: "#FFEEF6",
            color: COLORS.primaryColor,
            px: 2,
            py: 0.5,
            borderRadius: "16px",
            mt: 1,
          }}
        >
          <Typography variant="body4" sx={{ color: COLORS.primaryColor, fontFamily: "Poppins", fontSize: "14px", backgroundColor: "#FFEEF6",
             }}>
          {doctor.default_specialization}
          </Typography>
        </Box>
        <Box sx={{
          // gap: 5, // Adds spacing between child elements
        }}>
          <Typography
            variant="body2"
            color={COLORS.placeholderColor}
            sx={{ mt: 1 }}
          >
            Experience: {doctor.experience ? doctor.experience : 0} Years
          </Typography>
          <Typography
            variant="body2"
            color={COLORS.placeholderColor}
            sx={{ mt: 1, justifyContent: "flex-end" }}
          >
            {/* Languages: {doctor.languages} */}
            Language: {doctor.languages ?
              (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
              'Tamil, English'
            }
          </Typography>
        </Box>
      </Box>
    </Box>

    {/* Divider */}
    <Divider sx={{ my: 2 }} />

    {/* Buttons Section */}
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {/* <Typography variant="body2" color={COLORS.placeholderColor} sx={{ fontFamily: "Poppins" }}>
        Next Available:
      </Typography> */}
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          onClick={doctor.request_only === 0 ? onOpenAppointmentModal : onOpenRequestModal}
          sx={{
            backgroundColor: doctor.request_only === 0 ? '#962067' : '#ffd700',
            color: "#fff",
            textTransform: "none",
            fontFamily: "Poppins",
            '&:hover': {
              backgroundColor: doctor.request_only === 0 ? '#740150' : '#e6c200',
            },
            ':focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
            },
            ':active': {
              outline: 'none',
              boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
            },
          }}
        >
          {doctor.request_only === 0 ? 'Book Now' : 'Request'}
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: COLORS.primaryColor,
            borderColor: COLORS.primaryColor,
            textTransform: "none",
            fontFamily: "Poppins",
            "&:hover": { backgroundColor: "#f3e5f5", borderColor: COLORS.primaryColor, outline: "none" },
            "&:active": { backgroundColor: "#f3e5f5", borderColor: COLORS.primaryColor, outline: "none" },
            "&:focus": { backgroundColor: "#f3e5f5", borderColor: COLORS.primaryColor, outline: "none" },
          }}
        >
          View Profile
        </Button>
      </Box>
    </Box>
  </Card>
);

const DoctorDetailsModal = ({ open, onCloseModal, onOpenAppointmentModal, onOpenRequestModal, doctor, selectedUnit }) => {
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);

  if (!doctor) return null;

  const handleOpenAppointmentModal = () => {
    setOpenAppointmentModal(true);

  };

  const handleCloseAppointmentModal = () => {
    setOpenAppointmentModal(false);
  };

  return (
    <Modal open={open} onClose={onCloseModal} sx={{
      display: "flex",
      alignItems: "center", // Centers vertically
      justifyContent: "center", // Centers horizontally
    }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          p: 3,
          borderRadius: "12px",
          // boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
          width: "100%", // Adjust width as needed
          maxWidth: "700px", // Limit max width
          maxHeight: "90vh",
          // maxWidth: "600px",
          mx: "auto",
          mt: "3%",
          position: "relative",
          fontFamily: "Poppins",
          overflowY: "auto",
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: COLORS.primaryColor,
              mb: 2,
              fontFamily: "Poppins",
            }}
          >
            About the Doctor
          </Typography>
          <IconButton onClick={onCloseModal} sx={{
            color: COLORS.primaryColor, ':active': {
              boxShadow: `0 0 0 2px transparent`, border: '#fff', outline: 'none'
            },
          }}>
            <CloseIcon color={COLORS.primaryColor} />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar
            variant="square"
            sx={{
              width: 120,
              height: 140,
              borderRadius: "8px",
              backgroundColor: "#f8f8f8",
            }}
            src={doctor.photo}
          >
          </Avatar>
          <Box>
            <Typography
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                color: COLORS.textColor,
              }}
            >
              {doctor.first_name}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  // fontWeight: "bold",
                  color: COLORS.textColor,
                }}
              >
                {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
              </Typography>
              <Typography

                // variant="body2"
                // sx={{
                //   color: COLORS.primaryColor,
                //   // mt: 1,
                //   fontFamily: "Poppins",
                // }}
                sx={{ display: "list-item", fontFamily: "Poppins", color: COLORS.primaryColor, fontSize: '14px', ml: 3 }}
              >
                {doctor.default_specialization}
                {/* </ListItem> */}
              </Typography>
              {/* </List> */}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
              <IoLanguageOutline color={COLORS.placeholderColor} sx={{ fontSize: 40, marginRight: '10px', color: COLORS.placeholderColor }} />
              <Typography
                variant="body2"
                sx={{
                  ml: 1,
                  fontFamily: "Poppins",
                  color: COLORS.textColor,
                }}
              >
                {/* {doctor.languages} */}
                Language: {doctor.languages ?
                  (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                  'Tamil, English'
                }
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LuGraduationCap size={26} color={COLORS.primaryColor} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h7"
              sx={{
                fontWeight: "bold",
                mb: 1,
                ml: 1,
                fontFamily: "Poppins",
                color: COLORS.textColor,
              }}
            >
              Education
            </Typography>
            {doctor.designation ? (
              doctor.designation.map((edu, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    fontFamily: "Poppins",
                    color: COLORS.textColor,
                    fontSize: "14px",
                    ml: 1
                  }}
                >
                  {edu}
                </Typography>
              ))) : (
              ''
            )}
          </Box>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography
          variant="h7"
          sx={{
            fontWeight: "bold",
            mb: 1,
            fontFamily: "Poppins",
            color: COLORS.textColor,
          }}
        >
          Specialization & Experience
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Poppins",
            fontSize: "14px",
            color: COLORS.textColor,
          }}
        >
          {doctor.description}
        </Typography>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={doctor.request_only === 0 ? onOpenAppointmentModal : onOpenRequestModal}
            sx={{
              backgroundColor: doctor.request_only === 0 ? '#962067' : '#ffd700',
              textTransform: "none",
              fontFamily: "Poppins",
              '&:hover': {
                backgroundColor: doctor.request_only === 0 ? '#740150' : '#e6c200',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              ':active': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
            }}
          >
            {doctor.request_only === 0 ? 'Book Now' : 'Request'}
          </Button>
        </Box>

        {/* Appointment Modal */}
        {/* {openAppointmentModal && (
          <AppointmentModal
            open={openAppointmentModal}
            onCloseAppointment={handleCloseAppointmentModal}
            doctor={doctor}
            selectedUnit={selectedUnit}
          />
        )} */}
      </Box>
    </Modal>
  );
};

const DoctorsList = () => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [doctorsDetails, setDoctorsDetails] = useState([]);
  const [page_size, setPage_size] = useState(80);
  const [page, setPage] = useState(1);
  const [next_page, setNext_page] = useState(null);
  const [doctorListCount, setDoctorListCount] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  // const displayedDoctors = showAll1 ? doctorsDetails : doctorsDetails.slice(0, 4);
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [doctor, setDoctor] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const selectedUnit = queryParams.get('selectedUnit');
  const setSelectedUnit = queryParams.get('setSelectedUnit');
  const unitAddres = queryParams.get('unitAddress');
  const unitId = queryParams.get('unitid');
  const city = queryParams.get('city');
  const [unitAddress, setUnitAddress] = useState(unitAddres);
  const [isDoctorDetailsModalOpen, setDoctorDetailsModalOpen] = useState(false);
  const [isAppointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [isRequestModalOpen, setRequestModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openLogin, setOpenLogin] = useState(false);
  // const doctors = JSON.parse(queryParams.get('doctors'));
  // const displayedDoctors = doctors.slice(0, 4);
  const specialtyId = queryParams.get('spId');
  const spec_name = queryParams.get('specName');
  console.log('uuuu', specialtyId);
  const token = localStorage.getItem('user_token');
  useEffect(() => {
    const initializeData = async () => {

      specialityDoctorList();
      // }
    };

    initializeData();
  }, []);
  useEffect(() => {
          console.log('opnnnnn', token);
          // if (token) {
          onCallProfileApi();
          // upcomingEventsApiCall();
          // }
      }, []);

  const onCallProfileApi = async () => {
          const response = await getProfileApi();
          console.log('rrr', response);
          if (isResponseIsValid(response)) {
              dispatch(profileImageSaved(response.data.photo));
              setData(response.data);
              setName(response.data.name);
          }
      };

  const specialityDoctorList = async () => {
    try {
      const data = [(
        unitId,
        city,
        page,
        page_size,
        search,
        specialtyId,
        spec_name
      )];
      console.log('aaaaaaa', data);
      const doctorsResponse = await doctorsListInConsultation(
        unitId,
        city,
        1,
        5,
        search,
        specialtyId,
        spec_name,
      );

      if (isResponseIsValid(doctorsResponse)) {
        if (page == 1) {
          setDoctorListCount(doctorsResponse.data.total_count);
          setDoctorsDetails(doctorsResponse.data.data);

        }
        else {
          setDoctorsDetails(prevDoctorsDetails => [
            ...prevDoctorsDetails,
            ...doctorsResponse.data.data,
          ]);
          console.log('dcrrrrr', doctorsDetails);
        }
      } else {
        if (doctorsResponse?.data) {
          snackBar(doctorsResponse?.data);
        } else {
          snackBar(Headers.apiError);
        }
      }
      setTimeout(() => {
        setLoader(false);
        setInitialLoader(false);
      }, 400);
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
        setInitialLoader(false);
        snackBar(Headers.apiError);
      }, 500);

    }
  };

  const handleClose = () => {
    setOpenLogin(false);
};

const handleYes = () => {
    console.log('User signed out');
    setOpenLogin(false); // Close the current modal
    // onCloseAppointment(); // Notify the other page to close its modal
    setTimeout(() => {
        setIsDrawerOpen(true); // Open LoginDrawer after ensuring the previous modal closes
    }, 0);
    // Add your sign-out logic here
};

  const handleCardClick = (doctor) => {
    setSelectedDoctor(doctor);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedDoctor(null);
    setIsModalOpen(false);
  };

  const handleOpenDoctorDetailsModal = (doctor) => {
    setSelectedDoctor(doctor);
    setDoctorDetailsModalOpen(true);
    setAppointmentModalOpen(false); // Ensure the other modal is closed
  };

  const handleCloseDoctorDetailsModal = () => {
    setDoctorDetailsModalOpen(false);
  };

  const handleOpenAppointmentModal = () => {
    setAppointmentModalOpen(true);
    setDoctorDetailsModalOpen(false); // Close DoctorDetailsModal when AppointmentModal opens
  };

  const handleOpenRequestModal = () => {
    if(name){
    setRequestModalOpen(true);
    setDoctorDetailsModalOpen(false); 
    }
    else{
      setOpenLogin(true);
      setRequestModalOpen(false);
      setDoctorDetailsModalOpen(false);
    }
  };

  const handleCloseAppointmentModal = () => {
    setAppointmentModalOpen(false);
    // setIsDrawerOpen(true);
  };

  const handleCloseRequestModal = () => {
    setRequestModalOpen(false);
    // setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setIsModalOpen(true);
  };
  const onClose1 = () => {
    setIsDrawerOpen(false);
    // setIsModalOpen(true);
  };
  const closeDrawer1 = () => {
    setIsDrawerOpen(false);
    setIsModalOpen1(true);
  };
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
    setAppointmentModalOpen(false);
  };
  const handleCloseDrawer = () => {
    // setIsDrawerOpen(false);
    // setAppointmentModalOpen(false);
  };

  return (
    <Box>
      <Header />
      <Box sx={{ p: { xs: 2, md: 4 }, maxWidth: "800px", mx: "auto" }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton onClick={() => window.history.back()} sx={{
            color: '#962067', size: '14px', ':active': {
              boxShadow: `0 0 0 2px transparent`, outline: 'none', border: '#fff'
            },
          }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h5" sx={{
            fontWeight: "bold", mb: 1, fontFamily: "Poppins", color: COLORS.primaryColor, ':active': {
              boxShadow: `0 0 0 2px transparent`,
            },
          }}>
            Our Doctors
          </Typography>
        </Box>
        <Typography variant="body1" color={COLORS.textColor} sx={{ mb: 3, fontFamily: 'Poppins' }}>
          Find and book appointments with our specialists
        </Typography>
        <Box sx={{ cursor: "pointer", }}>
          {doctorsDetails.map((doctor, index) => (
            <DoctorCard key={index} doctor={doctor} onOpenAppointmentModal={handleOpenAppointmentModal} onOpenRequestModal={handleOpenRequestModal} onClick={() => handleOpenDoctorDetailsModal(doctor)} />
          ))}
        </Box>
      </Box>
      <DoctorDetailsModal
        open={isDoctorDetailsModalOpen}
        onCloseModal={handleCloseDoctorDetailsModal}
        onOpenAppointmentModal={handleOpenAppointmentModal}
        onOpenRequestModal={handleOpenRequestModal}
        doctor={selectedDoctor}
        selectedUnit={selectedUnit}
        unitAddres={unitAddres}
        unitId={unitId}
      />
      {isAppointmentModalOpen && (
        <AppointmentModal
          open={isAppointmentModalOpen}
          onOpenAppointmentModal={handleOpenAppointmentModal}
          onCloseAppointment={handleCloseAppointmentModal}
          onDrawerOpen={handleDrawerOpen}
          doctor={selectedDoctor}
          selectedUnit={selectedUnit}
        />
      )}
      {isRequestModalOpen && (
        <RequestModal
          open={isRequestModalOpen}
          onOpenAppointmentModal={handleOpenRequestModal}
          onCloseAppointment={handleCloseRequestModal}
          closeDrawer1={closeDrawer1}
          closeDrawer={closeDrawer}
          onClose1={onClose1}
          onDrawerOpen={handleDrawerOpen}
          doctor={selectedDoctor}
          selectedUnit={selectedUnit}
        />
      )}
      <TermsAndConditionsModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
      <PrivacyPolicy
        isOpen={isModalOpen1}
        onRequestClose={() => setIsModalOpen1(false)}
      />
      <Dialog open={openLogin} onClose={handleClose}>
                <DialogTitle sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 'bold', color: '#962067' }}>
                    Continue Appointment Booking
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            color: '#333333',
                            textAlign: 'center',
                        }}
                    >
                        Press continue to login for available slots
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        sx={{
                            borderRadius: '20px',
                            textTransform: 'none',
                            color: '#333',
                            borderColor: '#962067',
                            '&:hover': {
                                backgroundColor: '#fff',
                                borderColor: '#962067',
                            },
                        }}
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleYes}
                        sx={{
                            borderRadius: '20px',
                            textTransform: 'none',
                            backgroundColor: '#962067',
                            '&:hover': {
                                backgroundColor: '#b63a8a',
                            },
                        }}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <LoginDrawer isOpen={isDrawerOpen} closeDrawer={closeDrawer} closeDrawer1={closeDrawer1} onClose1={onClose1} />
    </Box>
  );
};
export default DoctorsList;
