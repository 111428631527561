import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Images from "../constants/Images";
import { COLORS } from "../constants/Theme";

const Footer = () => {
  const footerLinks = {
    About: [
      { label: "Overview", href: "https://www.kauveryhospital.com/about/overview/" },
      { label: "Our Management Team", href: "https://www.kauveryhospital.com/about/our-management-team/" },
      { label: "Awards", href: "https://www.kauveryhospital.com/about/awards/" },
      { label: "Careers", href: "https://www.kauveryhospital.com/about/careers/" },
    ],
    "Centers of Excellence": [
      { label: "Cardiac Science", href: "https://www.kauveryhospital.com/centers-of-excellence-and-specialties/cardiology/" },
      { label: "Neurology", href: "https://www.kauveryhospital.com/centers-of-excellence-and-specialties/neurology-and-neuroscience/" },
      { label: "General Medicine", href: "https://www.kauveryhospital.com/centers-of-excellence-and-specialties/general-medicine/" },
      { label: "Diabetology", href: "https://www.kauveryhospital.com/centers-of-excellence-and-specialties/diabetology/" },
    ],
    Specialties: [
      { label: "Anesthesiology", href: "https://www.kauveryhospital.com/centers-of-excellence-and-specialties/anesthesiology/" },
      { label: "Critical Care", href: "https://www.kauveryhospital.com/centers-of-excellence-and-specialties/critical-care/" },
      { label: "Dental Maxillofacial", href: "https://www.kauveryhospital.com/centers-of-excellence-and-specialties/dental/" },
      { label: "Dermatology", href: "https://www.kauveryhospital.com/centers-of-excellence-and-specialties/dermatology/" },
    ],
    "Patients & Visitors": [
      { label: "Overview", href: "https://www.kauveryhospital.com/patients-visitors/" },
      { label: "Patient Safety", href: "https://www.kauveryhospital.com/patients-visitors/surgery/" },
      { label: "Admissions", href: "https://www.kauveryhospital.com/patients-visitors/admissions/" },
      { label: "Preparing for Surgery", href: "https://www.kauveryhospital.com/patients-visitors/surgery/" },
    ],
  };

  return (
    <Box
      sx={{
        backgroundColor: "#8B2E5D",
        color: "white",
        padding: "40px",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        position: "relative",
        mt: 4,
      }}
    >
      {/* Logo Section */}
      <Box>
        <img
          src={Images.logo}
          alt="Kauvery Hospital Logo"
          style={{ width: "180px", marginBottom: "16px", marginLeft: '40px' }}
        />        
      </Box>

      {/* Footer Links */}
      {Object.entries(footerLinks).map(([section, links]) => (
        <Box key={section} sx={{ margin: "0 20px" }}>
          <Typography variant="h7" sx={{ fontWeight: "bold", marginBottom: 2, fontFamily: 'Poppins', mb: 2 }}>
            {section}
          </Typography>
          {links.map((link) => (
            <Typography key={link.label}>
              <Link href={link.href} sx={{ color: "white", textDecoration: "none", fontFamily: 'Poppins', fontSize: '14px', mt: 1, 
                "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
               }}>
                {link.label}
              </Link>
            </Typography>
          ))}
        </Box>
      ))}

      {/* Stay Connected Section */}
      <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
        }}
      >
        <Box sx={{}}>
        <Typography>© 2025 Kauvery Hospital. All Rights Reserved.</Typography>
        <Typography >
          <Link href="/privacy-policy" sx={{ color: "white", 
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
           }}>
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link href="/terms-and-conditions" sx={{ color: "white", 
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
           }}>
            T&C
          </Link>
        </Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', ml: 8}}>
        <Typography variant="h6" sx={{ fontWeight: "bold"}}>
          Stay Connected
        </Typography>
        <Box>
          <IconButton href="https://www.facebook.com/kauveryhospitalchennai/" target="_blank" sx={{ color: "white",
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
          }}>
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://x.com/kauveryhospital" target="_blank" sx={{ color: "white", 
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
          }}>
            <TwitterIcon />
          </IconButton>
          <IconButton href="https://www.instagram.com/kauveryhospital/" target="_blank" sx={{ color: "white", 
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
          }}>
            <InstagramIcon />
          </IconButton>
          <IconButton href="https://www.youtube.com/c/KauveryHospital" target="_blank" sx={{ color: "white",
            "&:hover": { backgroundColor: 'transparent', borderColor: COLORS.primaryColor, outline: "none", color: '#fff' },
                            "&:active": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
                            "&:focus": { backgroundColor: 'transparent', outline: "none", color: '#fff' },
          }}>
            <YouTubeIcon />
          </IconButton>
        </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
