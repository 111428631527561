import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, Button, TextField, InputAdornment, Chip, List, ListItem, MenuItem, Grid, Select, OutlinedInput } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Header from "./HomeHeader"; // Import the Header component
import Images from "../constants/Images";
import { COLORS } from "../constants/Theme";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { mixpanel_event } from "./mixpanel/app";
import axios from 'axios';
import SearchIcon from "@mui/icons-material/Search";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { height, width } from "@fortawesome/free-solid-svg-icons/fa0";
import GooglePlayIcon from "@mui/icons-material/Google"; // Google icon
import Footer from "./Footer";
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material';
import {
    addFeedback,
    getFeedbackList,
    familyMembersList,
    getProfileApi,
    units,
    nearbyUnits,
    nearbyUnitsCoordinates,
    nearbyUnitsRegion,
    nearbyUnitsWithoutRegion,
    reScheduleBooking,
    specializationList,
    specializationList1,
    upcomingEvents,
    updateBooking,
    specializationListWithUnitID,
    specialization,
    doctorsListInConsultation,
    doctorsListInConsultationwithoutPagenation
} from '../utils/apiCalls';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { profileImageSaved, unitNameData } from '../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import LoginDrawer from '../components/LoginDrawer';
import { AppContext } from '../navigation/AppContext';
import { useMixpanel } from "./mixpanel/Analytics";
import { mixpanelConfig } from "./mixpanel/app";
import {
    clearInfo,
    clearItem,
    getPayCheckoutSuccess,
    getSecondaryProfileID,
    getUserInformation,
    getWebHookCheckoutCall,
    setSecondaryProfileID,
    setUserInformation,
} from '../utils/LocalStorage';
import {
    currentRegion,
    currentProfileRawData
} from '../store/actions/homeActions';
import {
    customerCareCallNo,
    customerCareNumberData,
} from '../store/actions/bookingActions';
import ConsultationCard from "./ConsultationCard.js";
// import AppleIcon from "@mui/icons-material/Apple"; // Apple icon

const specialties = [
    {
        icon: "💜",
        title: "Cardio Thoracic Surgery",
        description: "Heart, lung, and vascular surgery",
        doctors: "12 Doctors",
        waitTime: "~2-3 days wait",
    },
    {
        icon: "🦴",
        title: "Orthopedics",
        description: "Surgery involving the musculoskeletal system (bones, ligaments, joints, tendons)",
        doctors: "15 Doctors",
        waitTime: "~1-2 days wait",
    },
    {
        icon: "🩺",
        title: "Gastroenterology",
        description: "For stomach problems",
        doctors: "10 Doctors",
        waitTime: "~1 day wait",
    },
    {
        icon: "👁️",
        title: "Ophthalmology",
        description: "Eye care and surgery",
        doctors: "8 Doctors",
        waitTime: "~1 day wait",
    },
    {
        icon: "🧠",
        title: "Neurology",
        description: "Brain and nervous system disorders",
        doctors: "10 Doctors",
        waitTime: "~2-4 days wait",
    },
];

const videos = [
    { url: "https://www.w3schools.com/html/mov_bbb.mp4", title: "Video 1" },
    { url: "https://www.w3schools.com/html/movie.mp4", title: "Video 2" },
    { url: "https://www.w3schools.com/html/mov_bbb.mp4", title: "Video 3" },
];

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024, // Desktop/Laptop
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768, // Tablets
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480, // Mobile
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const HomePage = () => {
    const mixpanel = useMixpanel();
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const user = {
        image: "", // Set an image URL if the user is logged in; leave empty if not logged in
    };
    const history = useHistory();
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const [token, setToken] = useState('');
    const [selectedUnit, setSelectedUnit] = useState(localStorage.getItem('selectedUnit') || '');
    // const [specialties, setSpecialities] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [unitAddress, setUnitAddress] = useState(localStorage.getItem('unitAddress') || '');
    const [showMoreSpecialties, setShowMoreSpecialties] = useState(false);
    const [showMoreDoctors, setShowMoreDoctors] = useState(false);
    const [spID, setSPID] = useState();
    const [name, setName] = useState('');
    const [unitid, setUnitid] = useState(localStorage.getItem('unitid') || '6555b2a5b1bfa3ab5b2fcd1b');
    console.log('uuiii', unitid);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState('');
    const [locationPopupOpen, setLocationPopupOpen] = useState(false);
    const [locations, setLocations] = useState('');
    const [cancelBookingDetails, setCancelBookingDetails] = useState({});
    const [mhcCancelBookingDetails, setMhcCancelBookingDetails] = useState({});
    const [payAtHos, setPayAtHos] = useState(0);
    const [mhcReschedulePopup, setMhcReschedulePopup] = useState(false);
    const [isRescheduled, setIsRescheduled] = useState(false);
    const [requestId, setRequestId] = useState(false);
    const [specialityCount, setSpecialityCount] = useState(0);
    const [specialtiesList, setSpecialtiesList] = useState([]);
    const [requestPopup, setRequestPopup] = useState(false);
    const [cancelBookingPopup, setCancelBookingPopup] = useState(false);
    const [mhcCancelBookingPopup, setMhcCancelBookingPopup] = useState(false);
    const [page_size, setPage_size] = useState(80);
    const [page, setPage] = useState(1);
    const [initialLoader, setInitialLoader] = useState(false);
    const [next_page, setNext_page] = useState(null);
    const [doctorListCount, setDoctorListCount] = useState('');
    const [doctorsDetails, setDoctorsDetails] = useState([]);
    const [nameData, setNameData] = React.useState([]);
    const [relationData, setRelationData] = React.useState([]);
    const [showAll1, setShowAll1] = useState(false);
    const [showAll2, setShowAll2] = useState(false);
    const [eventData, setEventData] = useState([]);
    const [search, setSearch] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [doctor, setDoctor] = useState([]);
    const [specialtiesName, setSpecialtiesName] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    // const terms=specialtiesName? specialtiesName.map((term, index) => {term.department}) : [];
    console.log('evennnn', eventData);
    const currentRegionSelected = useSelector(
        // current region
        state => state?.homeReducer?.currentRegion,
    );
    const [city, setCity] = useState(currentRegionSelected ? currentRegionSelected : "");
    console.log(currentRegionSelected, 'reggggggg');
    // const { Auth } = useContext(AppContext);

    const [unitId, setUnitId] = useState('');
    const [unit, setUnits] = useState([]);
    const [loader, setLoader] = useState(false);
    const [locationGranted, setLocationGranted] = useState(false);
    const [selectedCity, setSelectedCity] = useState("");
    const [cityOptions] = useState(["New York", "Los Angeles", "Chicago", "Houston", "Miami"]);

    const [tab, setTab] = React.useState(0);
    const profile_information = useSelector(
        state => state?.homeReducer?.profile_info,
    );

    useEffect(() => {
        if(!selectedUnit){
            setShowPopup(true);
        }
        setTimeout(() => {
        if(!name){
        if (locationGranted) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    // Fetch city name using reverse geocoding (Google Maps API, OpenCage, etc.)
                    const response = await fetch(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                    );
                    const data = await response.json();
                    setCity(data.address.city || "Unknown City");
                    if (data.address.city === 'Chennai') {
                        const selectedUnitName = 'Chennai - Mylapore';
                        setSelectedUnit('Chennai - Mylapore')
                        const selectedOption = dropdownOptions.find(option => option.name === selectedUnitName);
                        console.log('adrsssssssss', selectedOption);
                        setUnitid((prevUnitid) => {
                            console.log("Previous unitid:", prevUnitid);
                            return selectedOption._id.$oid;
                        });

                        setUnitAddress((prevUnitAddress) => {
                            console.log("Previous unitAddress:", prevUnitAddress); // Access previous value
                            return selectedOption.address;
                        });

                        console.log('idddddddd', unitid);
                        specialtiesListApiCall(selectedOption._id.$oid, page, page_size, city);
                        combinedApiCall(selectedOption._id.$oid);
                    }
                    if (data.address.city === 'Bangalore') {
                        const selectedUnitName = 'Electroniccity';
                        setSelectedUnit('Electroniccity')
                        const selectedOption = dropdownOptions.find(option => option.name === selectedUnitName);
                        console.log('adrsssssssss', selectedOption);
                        setUnitid((prevUnitid) => {
                            console.log("Previous unitid:", prevUnitid);
                            return selectedOption._id.$oid;
                        });

                        setUnitAddress((prevUnitAddress) => {
                            console.log("Previous unitAddress:", prevUnitAddress); // Access previous value
                            return selectedOption.address;
                        });

                        console.log('idddddddd', unitid);
                        specialtiesListApiCall(selectedOption._id.$oid, page, page_size, city);
                        combinedApiCall(selectedOption._id.$oid);
                    }
                    else if (data.address.city === 'Tirunelveli') {
                        const selectedUnitName = 'Tirunelveli';
                        setSelectedUnit('Tirunelveli');
                        const selectedOption = dropdownOptions.find(option => option.name === selectedUnitName);
                        console.log('adrsssssssss', selectedOption);
                        setUnitid((prevUnitid) => {
                            console.log("Previous unitid:", prevUnitid);
                            return selectedOption._id.$oid;
                        });

                        setUnitAddress((prevUnitAddress) => {
                            console.log("Previous unitAddress:", prevUnitAddress); // Access previous value
                            return selectedOption.address;
                        });

                        console.log('idddddddd', unitid);
                        specialtiesListApiCall(selectedOption._id.$oid, page, page_size, city);
                        combinedApiCall(selectedOption._id.$oid);
                    }
                    else if (data.address.city === 'Tiruchirappalli') {
                        const selectedUnitName = 'Trichy - Cantonment';
                        setSelectedUnit('Trichy - Cantonment');
                        const selectedOption = dropdownOptions.find(option => option.name === selectedUnitName);
                        console.log('adrsssssssss', selectedOption);
                        setUnitid((prevUnitid) => {
                            console.log("Previous unitid:", prevUnitid);
                            return selectedOption._id.$oid;
                        });

                        setUnitAddress((prevUnitAddress) => {
                            console.log("Previous unitAddress:", prevUnitAddress); // Access previous value
                            return selectedOption.address;
                        });

                        console.log('idddddddd', unitid);
                        specialtiesListApiCall(selectedOption._id.$oid, page, page_size, city);
                        combinedApiCall(selectedOption._id.$oid);
                    }
                    else if (data.address.city === 'Salem') {
                        const selectedUnitName = 'Salem';
                        setSelectedUnit('Salem');
                        const selectedOption = dropdownOptions.find(option => option.name === selectedUnitName);
                        console.log('adrsssssssss', selectedOption);
                        setUnitid((prevUnitid) => {
                            console.log("Previous unitid:", prevUnitid);
                            return selectedOption._id.$oid;
                        });

                        setUnitAddress((prevUnitAddress) => {
                            console.log("Previous unitAddress:", prevUnitAddress); // Access previous value
                            return selectedOption.address;
                        });

                        console.log('idddddddd', unitid);
                        specialtiesListApiCall(selectedOption._id.$oid, page, page_size, city);
                        combinedApiCall(selectedOption._id.$oid);
                    }
                    else if (data.address.city === 'Hosur') {
                        const selectedUnitName = 'Hosur';
                        setSelectedUnit('Hosur');
                        const selectedOption = dropdownOptions.find(option => option.name === selectedUnitName);
                        console.log('adrsssssssss', selectedOption);
                        setUnitid((prevUnitid) => {
                            console.log("Previous unitid:", prevUnitid);
                            return selectedOption._id.$oid;
                        });

                        setUnitAddress((prevUnitAddress) => {
                            console.log("Previous unitAddress:", prevUnitAddress); // Access previous value
                            return selectedOption.address;
                        });

                        console.log('idddddddd', unitid);
                        specialtiesListApiCall(selectedOption._id.$oid, page, page_size, city);
                        combinedApiCall(selectedOption._id.$oid);
                    }
                    console.log('cccc', city);
                    setShowPopup(false); // Close the popup
                },
                (error) => {
                    console.error("Error fetching location:", error);
                    setLocationGranted(false); // Handle error
                }
            );
        }
    }
}, 8000);
    }, [locationGranted]);

    const handleAllowLocation = () => {
        setLocationGranted(true);
    };

    const handleDenyLocation = () => {
        if (!selectedUnit) {
            setLocationPopupOpen(true);
        }

        else {

        }
        setLocationGranted(false);
        setShowPopup(false);
    };

    const handleCitySelect = (event) => {
        setSelectedCity(event.target.value);
    };

    const displayedEvent = showAll2 ? eventData : eventData.slice(0, 3);

    const handleChange = (event) => {
        const selectedUnitName = event.target.value;
        setSelectedUnit(selectedUnitName);
        const selectedOption = dropdownOptions.find(option => option.name === selectedUnitName);
        console.log('adrsssssssss', selectedOption);
        setUnitid((prevUnitid) => {
            console.log("Previous unitid:", prevUnitid);
            return selectedOption._id.$oid;
        });

        setUnitAddress((prevUnitAddress) => {
            console.log("Previous unitAddress:", prevUnitAddress); // Access previous value
            return selectedOption.address;
        });

        console.log('idddddddd', unitid);
        specialtiesListApiCall(selectedOption._id.$oid, page, page_size, city);
        combinedApiCall(selectedOption._id.$oid);
    };
    useEffect(() => {
        specialtiesListAPI(page, page_size);
        if (selectedUnit != '') {
            specialtiesListApiCall(unitid, page, page_size, city);
            combinedApiCall(unitid);
        }
        else { }
    }, [city, unitid, selectedUnit]);

    const combinedApiCall = async (unitid) => {
        try {
            console.log('UNIT ID PASSED IN API', unitid);
            const doctorsResponse = await doctorsListInConsultation(
                unitid,
                city,
                page,
                page_size
            );
            if (isResponseIsValid(doctorsResponse)) {
                if (page == 1) {
                    setDoctorListCount(doctorsResponse.data.total_count);
                    setDoctorsDetails(doctorsResponse.data.data);
                    console.log('ddddddd', doctorsResponse.data.data);
                }
                else {
                    setDoctorsDetails(prevDoctorsDetails => [
                        ...prevDoctorsDetails,
                        ...doctorsResponse.data.data,
                    ]);
                    console.log('ddddddd', doctorsDetails);
                }
            } else {
                if (doctorsResponse?.data) {
                    snackBar(doctorsResponse?.data);
                } else {
                    snackBar(Headers.apiError);
                }
            }
            setTimeout(() => {
                setLoader(false);
                setInitialLoader(false);
            }, 400);
        } catch (error) {
            setTimeout(() => {
                setLoader(false);
                setInitialLoader(false);
                snackBar(Headers.apiError);
            }, 500);

        }
    };

    const specialtiesListApiCall = async (
        unitid,
        currentPage,
        pageSize,
        // region,
    ) => {
        setLoader(true);
        try {
            const response = await specializationListWithUnitID(
                unitid,
                currentPage,
                pageSize,
                // region,
            );
            // console.log('asdsadasd', unitId, region);
            setSpecialtiesList([]);
            console.log(JSON.stringify(response.data.data), 'response123');
            if (isResponseIsValid(response)) {
                // if (currentPage != 1) {
                setSpecialtiesList(response.data.data);
                // setNext_page(response.data.next_page);
                // } else {
                // setSpecialtiesList([...specialtiesList, ...response.data.data]);
                // setNext_page(response.data.next_page);
                // }

                setTimeout(() => {
                    setInitialLoader(false);
                    setLoader(false);
                }, 500);
            } else {
                setInitialLoader(false);
                setLoader(false);
                setTimeout(() => {
                    if (response?.data) {
                        snackBar(response?.data);
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setInitialLoader(false);
            setLoader(false);
            setTimeout(() => {
                snackBar(Headers.apiError);
            }, 400);
        }
    };
    const specialtiesListAPI = async (
        currentPage,
        pageSize,
        // region,
    ) => {
        setLoader(true);
        try {
            const response = await specialization(
                currentPage,
                pageSize,
                // region,
            );
            // console.log('asdsadasd', unitId, region);
            console.log(JSON.stringify(response.data.data), 'response123');
            if (isResponseIsValid(response)) {
                if (currentPage != 1) {
                    setSpecialtiesName(response.data.data);
                    // setNext_page(response.data.next_page);
                } else {
                    setSpecialtiesName([...specialtiesList, ...response.data.data]);
                    // setNext_page(response.data.next_page);
                }

                setTimeout(() => {
                    setInitialLoader(false);
                    setLoader(false);
                }, 500);
            } else {
                setInitialLoader(false);
                setLoader(false);
                setTimeout(() => {
                    if (response?.data) {
                        snackBar(response?.data);
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setInitialLoader(false);
            setLoader(false);
            setTimeout(() => {
                snackBar(Headers.apiError);
            }, 400);
        }
    };

    useEffect(() => {
        const initializeData = async () => {
            unitDetails();
            // fetchDoctors(selectedUnit, spID);
        };
        initializeData();
        setTimeout(() => {
            setIsLoading(false); // Data fetch complete
        }, 2000);
    }, []);

    useEffect(() => {
        // unitDetails();
        nearbyUnitsWithoutCoordinates();

        // GettingLocationAccess();

        nearbyUnitsWithRegion(currentRegionSelected);
        onGetRelationList();
        // notificationBadgeUpdateApiCall();
        // pusherCallAPI();
        // getFeedbackListApiCall();    
        onCallProfileApi();
        upcomingEventsApiCall();

        const handlePusherReloadFamilyMembers = () => {
            //   onGetRelationList();
        };

        const handleEventsViewAllReload = () => {
            //   updateUpcomingEventsApiCall();
        };

        const handleLocationHomeReload = (event) => {
            console.log('locationHomeReload', event.location);
            specialtiesListApiCall(unitId, 1, 4, event.location);
        };

        const handleRescheduleData = (event) => {
            //   notificationBadgeUpdateApiCall();
            console.log('RESCHEDULE EVENT', event);
            if (event.is_reschedule === 1) {
                setTimeout(() => {
                    setIsRescheduled(true);
                }, 500);
            }
            //   updateUpcomingEventsApiCall();
        };

        const handleMhcRescheduleData = (event) => {
            //   notificationBadgeUpdateApiCall();
            console.log('MHC RESCHEDULE EVENT', event);
            if (event.is_reschedule === 1) {
                setTimeout(() => {
                    setMhcReschedulePopup(true);
                }, 500);
            }
            //   updateUpcomingEventsApiCall();
        };

        const handleLogoutAction = () => {
            console.log('SPlash Event');
            setTimeout(() => {
                // Auth();
                snackBar('Oops! You have been logged out. Please sign-in again');
            }, 1200);
        };

        // const handleFeedbackPopup = (event) => {
        //   console.log('Event triggered successful');
        //   notificationBadgeUpdateApiCall();
        //   updateUpcomingEventsApiCall();
        //   console.log('Event', event);
        //   if (event.isShowPopup) {
        //     setTimeout(() => {
        //       setFeedBackPopup(true);
        //     }, 500);
        //   }
        // };

        const handleRequestNowData = (event) => {
            console.log('Event', event);
            if (event.isShowPopup) {
                setTimeout(() => {
                    if (event.item) {
                        setRequestId(event.item.request_id);
                        if (event.item.message === 'success') {
                            setRequestPopup(true);
                        }
                    }
                }, 500);
            }
        };

        const handleCancelBookingData = (event) => {
            //   notificationBadgeUpdateApiCall();
            //   updateUpcomingEventsApiCall();
            if (event.isShowPopup) {
                console.log('itemitem', event.item);
                setCancelBookingDetails(event.item);
                setPayAtHos(event.pay_at_hos);
                setTimeout(() => {
                    setCancelBookingPopup(true);
                }, 1500);
            }
        };

        const handleMhcCancelBookingData = (event) => {
            //   notificationBadgeUpdateApiCall();
            //   updateUpcomingEventsApiCall();
            if (event.isShowPopup) {
                console.log('mhc canceled booking', event.item);
                setMhcCancelBookingDetails(event.item);
                setPayAtHos(event.pay_at_hos);
                setTimeout(() => {
                    setMhcCancelBookingPopup(true);
                }, 1000);
            }
        };

        // Adding event listeners
        window.addEventListener('pusherReloadFamilyMembers', handlePusherReloadFamilyMembers);
        window.addEventListener('eventsViewAllReload', handleEventsViewAllReload);
        window.addEventListener('locationHomeReload', handleLocationHomeReload);
        window.addEventListener('reScheduleData', handleRescheduleData);
        window.addEventListener('mhcReScheduleData', handleMhcRescheduleData);
        window.addEventListener('Logout_action', handleLogoutAction);
        // window.addEventListener('feedbackPopup', handleFeedbackPopup);
        window.addEventListener('requestNowData', handleRequestNowData);
        window.addEventListener('cancelBookingData', handleCancelBookingData);
        window.addEventListener('mhcCancelBookingData', handleMhcCancelBookingData);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('pusherReloadFamilyMembers', handlePusherReloadFamilyMembers);
            window.removeEventListener('eventsViewAllReload', handleEventsViewAllReload);
            window.removeEventListener('locationHomeReload', handleLocationHomeReload);
            window.removeEventListener('reScheduleData', handleRescheduleData);
            window.removeEventListener('mhcReScheduleData', handleMhcRescheduleData);
            window.removeEventListener('Logout_action', handleLogoutAction);
            // window.removeEventListener('feedbackPopup', handleFeedbackPopup);
            window.removeEventListener('requestNowData', handleRequestNowData);
            window.removeEventListener('cancelBookingData', handleCancelBookingData);
            window.removeEventListener('mhcCancelBookingData', handleMhcCancelBookingData);
        };
    }, [unitId, dropdownOptions, unitAddress, city]);

    const nearbyUnitsWithoutCoordinates = async () => {
        setLoader(true);
        try {
            const response = await nearbyUnitsWithoutRegion();
            if (isResponseIsValid(response)) {
                const region = response.data.current_region || response.data.regions[0];
                setCity(region);
                dispatch(currentRegion(region));
                setLocations(response.data.regions);

                let unit = response.data.data.find(it => it.is_focused === true);
                dispatch(unitNameData(unit.name));
                dispatch(customerCareNumberData(unit.customer_care));
                dispatch(customerCareCallNo(unit.customer_care_number));
                setLoader(false);
            } else {
                setLoader(false);
                snackBar(response?.data || Headers.apiError);
            }
        } catch (err) {
            setLoader(false);
            snackBar(Headers.apiError);
        }
    };

    const nearbyUnitsWithRegion = async region => {
        try {
            const response = await nearbyUnitsRegion(region);
            if (isResponseIsValid(response)) {
                // let unit = response.data.data.find(it => it.is_focused === true);
                // console.log('get units', unit);
                // dispatch(customerCareNumberData(unit.customer_care));
                // dispatch(customerCareCallNo(unit.customer_care_number));
                console.log('uuuuuu', response.data);
                if (region !== '') {
                    setCity(region);
                    dispatch(currentRegion(region));
                } else if (response.data.current_region !== 'undefined') {
                    setCity(response.data.current_region);
                    dispatch(currentRegion(response.data.current_region));
                } else {
                    setCity(response.data.regions[0]);
                    dispatch(currentRegion(response.data.regions[0]));
                }
                setLocations(response.data.regions);

                if (
                    response.data.data[0]._id.$oid !== null ||
                    response.data.data[0]._id.$oid !== undefined
                ) {
                    setUnitId(response.data.data[0]._id.$oid);

                    console.log('CONSULT CUSTOMER CARE NUM', response.data.data[0]);
                    dispatch(unitNameData(response.data.data[0].name));
                    dispatch(customerCareNumberData(response.data.data[0].customer_care));
                    dispatch(customerCareCallNo(response.data.data[0].customer_care_number));

                    // combinedApiCall(unit._id.$oid, true);
                }
            } else {
                setTimeout(() => {
                    if (response?.data) {
                        // snackBar(response?.data);
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setTimeout(() => {
                snackBar(Headers.apiError);
            }, 400);
        }
    };

    const unitDetails = async () => {
        try {
            const response = await units();
            if (isResponseIsValid(response)) {
                console.log('unitttttttttttttt', response.data);
                const unitNamesWithOids = response.data.data;
                setDropdownOptions(unitNamesWithOids);
            } else {
                setTimeout(() => {
                    if (response?.data) {
                        // snackBar(response?.data);
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setTimeout(() => {
                snackBar(Headers.apiError);
            }, 400);
        }
    };

    const handleUnitSelection = (unitName) => {
        setSelectedUnit(unitName); // Set the selected unit
        localStorage.setItem('selectedUnit', unitName); // Save selection in localStorage
        setLocationPopupOpen(false); // Close the popup after selection
        const selectedOption = dropdownOptions.find(option => option.name === unitName);
        console.log('adrsssssssss', selectedOption);
        if (selectedOption) {
            setUnitAddress(selectedOption.address);
            setUnitid(selectedOption._id.$oid);
        }
        localStorage.setItem('unitAddress', selectedOption.address);
        localStorage.setItem('unitid', selectedOption._id.$oid);
        // sendSelectedUnitToAPI(event.target.value);
        console.log('idddddddd', unitid);
        specialtiesListApiCall(selectedOption._id.$oid, page, page_size, city);
        combinedApiCall(selectedOption._id.$oid);
    };

    const upcomingEventsApiCall = async () => {
        setLoader(true);
        try {
            const response = await upcomingEvents(1, 10);
            console.log(JSON.stringify(response), 'EVENT RESPONSE');
            if (isResponseIsValid(response)) {
                console.log(JSON.stringify(response.data.data), 'EVENT RESPONSE DATA');
                setEventData(response.data.data);
                setTimeout(() => {
                    setLoader(false);
                }, 200);
            } else {
                setLoader(false);
                setTimeout(() => {
                    snackBar(JSON.stringify(response.data));
                }, 400);
            }
        } catch (err) {
            setLoader(false);
            setTimeout(() => {
                snackBar(JSON.stringify(err));
            }, 400);
        }
    };

    const onGetRelationList = async () => {
        // setListLoader(true)

        try {
            const response = await familyMembersList();
            if (isResponseIsValid(response)) {
                const relations = response.data.data;
                setRelationData(response.data.data);
                console.log('relatttttttt', response.data.data);
                // pusherCallAPI(relations);
                getSecondaryProfileID('Secondary_Profile_ID')
                    .then(res => {
                        let json = JSON.parse(res);
                        console.log('familyMembersList', response)
                        const relations = response.data.data;
                        relations.forEach(item => {
                            //check if the active profile is deleted if true check for primary profile id and set true
                            // if (item.app_profile_id === profile_information.app_profile_id) {
                            if (item.app_profile_id === json) {
                                item.isSelected = true;
                            } else {
                                item.isSelected = false;
                            }
                        });
                        setNameData(relations);
                        dispatch(currentProfileRawData(relations));

                    })
                    .catch(err => {
                        // setListLoader(false)
                        console.log('err', err);
                    });
            } else {
                // setListLoader(false)
            }
        } catch (err) {
            //   setListLoader(false)
            setTimeout(() => {
                snackBar('eeeeeeeee', JSON.stringify(err));
            }, 400);
        }
    };

    const openDrawer = () => {
        console.log('opppp')
        setIsDrawerOpen(true);
    };

    // Function to close the drawer
    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    const onCallProfileApi = async () => {
        const response = await getProfileApi();
        console.log(response);
        if (isResponseIsValid(response)) {
            dispatch(profileImageSaved(response.data.photo ? response.data.photo : ''));
            setName(response.data.name);
        }
    };

    const handleLocationDisagree = () => {
        setLocationPopupOpen(false);
    };
    const sessionBookingStart = () => {
        mixpanel.timeEvent(mixpanel_event.BOOKING_SESSION_HOME);
    };

    const toggleView = () => {
        setShowAll((prevState) => !prevState);
    };
    console.log('sss', specialtiesList);
    // Determine how many specialties to show
    const [searchText, setSearchText] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const visibleSpecialties = showAll ? specialtiesList : specialtiesList.slice(0, 3);
    const filteredSpecialties = searchText ? specialtiesList.filter((specialty) =>
        specialty.department.toLowerCase().includes(searchText.toLowerCase())
    ) : '';
    console.log('vvvv', filteredSpecialties);

    const handleSpecialtyClick = async (specialtyId, unitId, spec_name, specialty) => {
        // setActiveSpecialty(specialtyId);
        // let profile_info = profile_information;
        // let property_input = {
        //     'Mobile number': profile_info.mobile_number,
        //     Age: profile_info.age,
        //     Gender: profile_info.gender,
        //     Relationship: profile_info.relationship,
        //     'Is Primary User': profile_info.is_primary_user,
        // };
        // mixpanel.track(
        //     mixpanel_event.DOCTOR_APPOINTMENT,
        //     property_input,
        // );
        // sessionBookingStart();
        const queryParams = new URLSearchParams({
            dropdownOptions: dropdownOptions,
            selectedUnit: selectedUnit,
            unitAddress: unitAddress,
            unitid: unitid,
            city: city,
            spId: specialtyId,
            specName: spec_name,
            setSelectedUnit: setSelectedUnit,
        }).toString();
        // history.push(`/doctors`);
        history.push(`/doctors?${queryParams}`);
    };

    const specialties = [
        "Cardiology",
        "General Medicine",
        "Pediatrics",
        "Diabetology",
        "ENT",
    ];

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        setShowSuggestions(true);
    };

    const handleSpecialityClick = (speciality) => {
        setSearchText(speciality);
        setShowSuggestions(false);
    };

    const handleFocus = () => {
        setShowSuggestions(true);
    };

    return (
        <Box>
            {/* Header Section */}
            <Header
                dropdownOptions={dropdownOptions}
                selectedUnit={selectedUnit}
                unitAddress={unitAddress}
                unitid={unitid}
                city={city}
                setSelectedUnit={setSelectedUnit}
                openDrawer={openDrawer}
            />

            {/* Main Content */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: { xs: 2, md: 5 },
                    py: 5,
                    bgcolor: "#FFEEF6",
                }}
            >
                {/* Text Section */}
                <Box sx={{ flex: 1, pr: { md: 3 }, textAlign: { xs: "center", md: "left" } }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold", color: COLORS.primaryColor, fontFamily: 'Poppins' }}>
                        Your Health Journey Starts Here
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: 2, color: "#000", fontFamily: 'Poppins' }}>
                        Expert Care, Simple Access
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1, color: COLORS.textColor, fontFamily: 'Poppins' }}>
                        Book appointments, check-ups, and manage your health in one place.
                    </Typography>
                    <Box
                        sx={{
                            width: "100%",
                            maxWidth: "500px",
                            backgroundColor: "#fff",
                            borderRadius: "16px",
                            padding: "10px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" }, // Responsive layout
                            alignItems: "center",
                            gap: "16px",
                            mt: 2
                        }}
                    >
                        {/* Left Side: Scanner Image */}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={Images.qr} // Replace with your actual image URL
                                    alt="Scanner"
                                    style={{
                                        width: "150px",
                                        height: "150px",
                                        borderRadius: "16px",
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </Box>
                        </Box>

                        {/* Right Side: Content */}
                        <Box
                            sx={{
                                flex: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold", color: COLORS.textColor, marginBottom: "8px", fontFamily: 'Poppins' }}
                            >
                                Get the Kauvery Kare App
                            </Typography>
                            {/* <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                marginBottom: "16px",
                            }}
                        >
                            <Box sx={{ display: "flex", color: "#ffd700" }}>
                                {Array(5)
                                    .fill(null)
                                    .map((_, index) => (
                                        <span key={index}>&#9733;</span>
                                    ))}
                            </Box>
                            <Typography variant="body2" sx={{ color: "#666" }}>
                                4.8/5 (2000+ reviews)
                            </Typography>
                        </Box> */}
                            <List
                                sx={{
                                    color: "#666",
                                    marginBottom: "10px",
                                    paddingLeft: "20px",
                                    listStyle: "disc outside none",
                                    fontFamily: 'Poppins',
                                    fontSize: '14px'
                                }}
                            >
                                <ListItem sx={{ display: "list-item" }}>
                                    Book appointments instantly
                                </ListItem>
                                <ListItem sx={{ display: "list-item" }}>
                                    Access health records anytime
                                </ListItem>
                                <ListItem sx={{ display: "list-item" }}>
                                    Master health checkup booking
                                </ListItem>
                            </List>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "16px",
                                    mt: 0,
                                }}
                            >
                                {/* Google Play Button */}
                                <a
                                    href="https://play.google.com/store/search?q=kauvery+kare+app&c=apps&hl=en"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none" }}
                                >
                                    <img
                                        src={Images.googlePlay}
                                        alt="Get it on Google Play"
                                        style={{ width: "150px", height: "auto" }}
                                    />
                                </a>

                                {/* App Store Button */}
                                <a
                                    href="https://apps.apple.com/in/app/kauvery-kare/id6462783769"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none" }}
                                >
                                    <img
                                        src={Images.applePlay}
                                        alt="Download on the App Store"
                                        style={{ width: "150px", height: "auto", }}
                                    />
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Image Section */}
                <Box
                    sx={{
                        width: { xs: "100%", md: "50%" },
                        mt: { xs: 3, md: 0 },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img
                        src={Images.family_front}
                        alt="Health Journey"
                        style={{
                            maxWidth: "100%",
                            height: "auto",
                            borderRadius: "12px",
                        }}
                    />
                </Box>
            </Box>
            {eventData != '' && (
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1, fontFamily: 'Poppins', color: COLORS.primaryColor, textAlign: 'center', mt: 1 }}>
                        Upcoming Events
                    </Typography>
                    <Box>
                        {eventData != '' && (
                            <Box>
                                {displayedEvent.map((event, index) => (
                                    <ConsultationCard
                                        key={event.booking_id}
                                        type={event.slot_details[0].slot_type.toLowerCase() === "offline" ? "hospital" : "video"}
                                        date={new Date(event.slot_details[0].slot_date).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })}
                                        time={event.slot_details[0].slot_start_time}
                                        doctor={event.slot_details[0].doctor_name}
                                        patient={event.patient_name}
                                        location={event.slot_details[0].unit_details.unit_name}
                                        status={event.ui_booking_status.toLowerCase()}
                                        fee={event.payment_status === "pending" ? "1000" : null}
                                        tab='0'
                                        booking_id={event.booking_id}
                                        event={event}
                                        paymentStatus={event.payment_status}
                                    />
                                ))}
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        sx={{
                                            mb: 2, color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                                                outline: 'none',
                                                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                            },
                                            ':hover': {
                                                backgroundColor: 'transparent',
                                            },
                                            ':active': {
                                                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                            },
                                        }}
                                        onClick={() => setShowAll2(!showAll2)}
                                    >
                                        {showAll2 ? 'View Less' : 'View all'}
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
            {/* <ConsultationCard /> */}
            <Box
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    // gap: "10px",
                    // padding: "5px",
                    backgroundColor: "#f9f6fc",
                }}
            >
                {/* Cards Section */}
                <Box
                    sx={{
                        // px: { xs: 2, md: 5 },
                        py: 1,
                        display: "grid",
                        gridTemplateColumns: { xs: "5fr", md: "10fr 10fr" }, // Two cards per row on medium and larger screens
                        gap: 2, // Space between cards
                    }}
                >
                    {/* Card 1 */}
                    <Card
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            justifyContent: "space-between", // Distributes content without extra space
                            borderRadius: "12px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                            cursor: "pointer",
                            "&:hover": {
                                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)",
                            },
                        }}
                    >
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 1,
                                }}
                            >
                                <img
                                    src={Images.appointment}
                                    alt="Appointment"
                                    style={{ width: "50px", height: "50px" }}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        ml: 2,
                                        fontFamily: "Poppins",
                                        color: COLORS.textColor,
                                    }}
                                >
                                    Book an Appointment
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontFamily: "Poppins",
                                    color: COLORS.placeholderColor,
                                    textAlign: "center",
                                    mb: 2,
                                }}
                            >
                                Schedule a consultation with our expert doctors.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: COLORS.primaryColor,
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: COLORS.primaryColorHover,
                                    },
                                }}
                            >
                                Book Now →
                            </Button>
                        </CardContent>
                    </Card>

                    {/* Card 2 */}
                    <Card
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between", // Ensures content fits dynamically
                            borderRadius: "12px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                            cursor: "pointer",
                            "&:hover": {
                                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)",
                            },
                        }}
                    >
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 1,
                                }}
                            >
                                <img
                                    src={Images.healthCheckup}
                                    alt="Health Checkup"
                                    style={{ width: "50px", height: "50px" }}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        ml: 2,
                                        fontFamily: "Poppins",
                                        color: COLORS.textColor,
                                    }}
                                >
                                    Health Checkups
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontFamily: "Poppins",
                                    color: COLORS.placeholderColor,
                                    textAlign: "center",
                                    mb: 2,
                                }}
                            >
                                Comprehensive health packages tailored for you.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: COLORS.primaryColor,
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: COLORS.primaryColorHover,
                                    },
                                }}
                            >
                                Learn More →
                            </Button>
                        </CardContent>
                    </Card>
                </Box>

                {/* Search Card */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: "100%",
                        backgroundColor: "#fff",
                        borderRadius: "16px",
                        padding: "24px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <div style={{ padding: "5px", maxWidth: "500px", margin: "auto" }}>
                        <h6 style={{ fontWeight: "bold", color: COLORS.textColor, marginBottom: "8px", fontFamily: 'Poppins' }}>
                            What service can we help you find?
                        </h6>
                        <div
                            style={{
                                display: "flex", // Align items in a row
                                alignItems: "center", // Vertically center the input and button
                                gap: "10px", // Space between input and button
                                marginBottom: "10px",
                            }}
                        >
                            <input
                                type="text"
                                value={searchText}
                                placeholder="Search..."
                                onChange={handleSearchChange}
                                onFocus={handleFocus}
                                style={{
                                    flex: 1, // Input takes all available space
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                }}
                            />
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: COLORS.primaryColor,
                                    color: "#fff",
                                    textTransform: "none",
                                    borderRadius: "8px",
                                    padding: "8px 24px",
                                    fontFamily: 'Poppins',
                                    "&:hover": {
                                        backgroundColor: COLORS.primaryColor,
                                        outline: 'none'
                                    },
                                }}
                            >
                                Search
                            </Button>
                        </div>
                        {showSuggestions && (
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                    marginTop: "10px",
                                }}
                            >
                                {specialties
                                    .filter((item) =>
                                        item.toLowerCase().includes(searchText.toLowerCase())
                                    )
                                    .map((speciality) => (
                                        <div
                                            key={speciality}
                                            onClick={() => handleSpecialityClick(speciality)}
                                            style={{
                                                padding: "8px 8px",
                                                border: `1px solid ${COLORS.primaryColor}`,
                                                borderRadius: "20px",
                                                cursor: "pointer",
                                                fontSize: "12px",
                                                backgroundColor: '#FFEEF6',
                                                color: COLORS.primaryColor,
                                                fontFamily: 'Poppins',
                                            }}
                                        >
                                            {speciality}
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </Box>


                {/* App Download Card */}
                {/* <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        padding: "32px",
        backgroundColor: "#f9f6fc",
      }}
    > */}
                {/* App Download Card */}

            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'Column',
                    width: '98%',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '10px',
                    padding: '20px',
                    mt: 1,
                    ml: 1,
                    mb: 1,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mt: 1 }}>
                    {/* <LocationOnIcon sx={{}} /> */}
                    <Select
                        value={selectedUnit}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'Poppins': 'Select location' }}
                        IconComponent={ArrowDropDownIcon}
                        input={
                            <OutlinedInput
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LocationOnIcon sx={{ color: COLORS.primaryColor, fontSize: '16px' }} />
                                    </InputAdornment>
                                }
                                sx={{
                                    height: '25px',
                                    fontFamily: 'Poppins',
                                    color: COLORS.textColor,
                                    fontSize: '13px',
                                    alignItems: 'center',
                                    borderRadius: '9px',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    ':active': {
                                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Corrected syntax for boxShadow
                                    },
                                }}
                            />
                        }
                    >
                        <MenuItem disabled value="" sx={{ fontFamily: 'Poppins', fontSize: '13px', }}>
                            {selectedUnit}
                        </MenuItem>
                        {dropdownOptions.map(option => (
                            <MenuItem key={option.oid} value={option.name} sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '13px', }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                {/* <Grid container spacing={4} sx={{ mt: 2 }}> */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ maxWidth: "800px", margin: "auto", padding: "16px" }}>
                        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "16px", fontFamily: 'Poppins', color: COLORS.primaryColor }}>
                            Specialties
                        </Typography>
                        <Typography variant="subtitle2" sx={{ marginBottom: "24px", color: COLORS.textColor, fontFamily: 'POppins' }}>
                            Select a specialty to find the right doctor for your needs
                        </Typography>
                        {filteredSpecialties ? (
                            filteredSpecialties.map((specialty, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "16px",
                                        marginBottom: "16px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        borderRadius: "12px",
                                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                                        borderLeft: "5px solid #972168",
                                        '&:hover': {
                                            boxShadow: '0 0.5vw 1vw rgba(0,0,0,0.2)',
                                            borderBottom: "5px solid #972168",
                                        },
                                    }}
                                    // onClick={() => handleSpecialtyClick()}
                                    onClick={() => handleSpecialtyClick(specialty._id.$oid, unitid, specialty.department)}
                                >
                                    {/* Icon Section */}
                                    <Box
                                        sx={{
                                            flex: "0 0 50px",
                                            height: "50px",
                                            backgroundColor: "#fff",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                            fontSize: "24px",
                                            color: "#972168",
                                            marginRight: "16px",
                                        }}
                                    >
                                        {/* {specialty.icon} */}
                                        {specialty.icon ? (
                                            <img src={specialty.icon} alt="Specialty Icon" style={{ height: 'auto', width: '100%' }} />
                                        ) : (
                                            <img src={Images.generalMedicine} alt="Specialty Icon" style={{ height: 'auto', width: '90%' }} />
                                        )}
                                    </Box>

                                    {/* Details Section */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h7" sx={{ fontWeight: "", fontFamily: 'Poppins', color: COLORS.textColor }}>
                                            {specialty.department}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: "#666", marginBottom: "8px", fontFamily: 'Poppins', color: COLORS.placeholderColor }}>
                                            {specialty.description}
                                        </Typography>
                                        {/* <Typography
                                variant="caption"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    color: "#666",
                                }}
                            >
                                <span>{specialty.doctors}</span>
                                <span> {specialty.waitTime}</span>
                            </Typography> */}
                                    </Box>

                                    {/* Arrow Section */}
                                    <Box
                                        sx={{
                                            fontSize: "20px",
                                            color: "#8e44ad",
                                            marginLeft: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        &gt;
                                    </Box>
                                </Box>
                            ))) : (
                            visibleSpecialties.map((specialty, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "16px",
                                        marginBottom: "16px",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        borderRadius: "12px",
                                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                                        borderLeft: "5px solid #972168",
                                        '&:hover': {
                                            boxShadow: '0 0.5vw 1vw rgba(0,0,0,0.2)',
                                            borderBottom: "5px solid #972168",
                                        },
                                    }}
                                    // onClick={() => handleSpecialtyClick()}
                                    onClick={() => handleSpecialtyClick(specialty._id.$oid, unitid, specialty.department)}
                                >
                                    {/* Icon Section */}
                                    <Box
                                        sx={{
                                            flex: "0 0 50px",
                                            height: "50px",
                                            backgroundColor: "#fff",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                            fontSize: "24px",
                                            color: "#972168",
                                            marginRight: "16px",
                                        }}
                                    >
                                        {/* {specialty.icon} */}
                                        {specialty.icon ? (
                                            <img src={specialty.icon} alt="Specialty Icon" style={{ height: 'auto', width: '100%' }} />
                                        ) : (
                                            <img src={Images.generalMedicine} alt="Specialty Icon" style={{ height: 'auto', width: '90%' }} />
                                        )}
                                    </Box>

                                    {/* Details Section */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h7" sx={{ fontWeight: "", fontFamily: 'Poppins', color: COLORS.textColor }}>
                                            {specialty.department}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: "#666", marginBottom: "8px", fontFamily: 'Poppins', color: COLORS.placeholderColor }}>
                                            {specialty.description}
                                        </Typography>
                                        {/* <Typography
                                variant="caption"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    color: "#666",
                                }}
                            >
                                <span>{specialty.doctors}</span>
                                <span> {specialty.waitTime}</span>
                            </Typography> */}
                                    </Box>

                                    {/* Arrow Section */}
                                    <Box
                                        sx={{
                                            fontSize: "20px",
                                            color: "#8e44ad",
                                            marginLeft: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        &gt;
                                    </Box>
                                </Box>
                            )))}

                        {/* View All / View Less Button */}
                        <Box sx={{ textAlign: "center", marginTop: "16px" }}>
                            <Button
                                onClick={toggleView}
                                sx={{
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    color: COLORS.primaryColor,
                                    fontFamily: "POppins",

                                    "&:hover": { backgroundColor: 'transparent', outline: "none", color: COLORS.primaryColor },
                                    "&:active": { backgroundColor: 'transparent', outline: "none", color: COLORS.primaryColor },
                                    "&:focus": { backgroundColor: 'transparent', outline: "none", color: COLORS.primaryColor },
                                }}
                            >
                                {showAll ? "View Less" : "View All"}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {/* </Grid> */}
                <Box
                    sx={{
                        // mt: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}
                >
                    {/* <Typography
                        variant="h5"
                        sx={{ mb: 3, textAlign: "center", fontFamily: "Poppins", color: COLORS.primaryColor, fontWeight: 'bold' }}
                    >
                        Watch Our Videos
                    </Typography> */}
                    {/* <Box
                        sx={{
                            width: "100%",
                            maxWidth: "400px" // Restricts the slider's width for a centered look
                        }}
                    >
                        <Slider {...sliderSettings}>
                            {videos.map((video, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <video
                                        controls
                                        style={{
                                            width: "100%",
                                            borderRadius: "16px",
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                        }}
                                    >
                                        <source src={video.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </Box>
                            ))}
                        </Slider>
                    </Box> */}
                </Box>
                <Dialog open={locationPopupOpen} onClose={handleLocationDisagree}>
                    {/* <DialogTitle sx={{ fontFamily: 'Poppins' }}>Select Location</DialogTitle> */}
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2, fontFamily: 'Poppins', color: COLORS.primaryColor, }}>
                            Select the location
                        </DialogContentText>

                        {/* Displaying the units as clickable buttons */}
                        <Grid container spacing={2}>
                            {dropdownOptions.map(option => (
                                <Grid item xs={12} key={option.id}>
                                    <Button
                                        fullWidth
                                        variant={selectedUnit === option.name ? 'contained' : 'outlined'}
                                        // startIcon={<LocationOnIcon sx={{ color: COLORS.primaryColor }} />}
                                        onClick={() => handleUnitSelection(option.name)}
                                        sx={{
                                            fontFamily: 'Poppins',
                                            color: COLORS.textColor,
                                            fontSize: '14px',
                                            textAlign: 'left',
                                            justifyContent: 'flex-start',
                                            // borderRadius: '8px',
                                            border: 'none',
                                            textTransform: 'none',
                                            padding: '2px',
                                            ...(selectedUnit === option.UnitName && {
                                                backgroundColor: COLORS.primaryColor,
                                                color: '#fff',
                                            }),
                                        }}
                                    >
                                        {option.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Box>
            <Footer />
            {/* <LoginDrawer isOpen={isDrawerOpen} closeDrawer={closeDrawer} /> */}
            {showPopup && (
                <Dialog open={showPopup}>
                    <DialogTitle sx={{fontFamily: 'Poppins'}}>Enable Location</DialogTitle>
                    <DialogContent>
                        <Typography sx={{fontFamily: 'Poppins'}}>
                            This website needs your location to provide relevant information. Do you want to allow access to your location?
                        </Typography>
                        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" color={COLORS.primaryColor} onClick={handleAllowLocation} sx={{fontFamily: 'Poppins', backgroundColor: COLORS.primaryColor, color: '#fff', ':focus': {
                                                outline: 'none',
                                                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                            },
                                            ':hover': {
                                                backgroundColor: COLORS.primaryColor,
                                            },
                                            ':active': {
                                                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                            },}}>
                                Yes, Allow
                            </Button>
                            <Button variant="outlined" color={COLORS.primaryColor} onClick={handleDenyLocation} sx={{fontFamily: 'Poppins', ml: 2, ':focus': {
                                                outline: 'none',
                                                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                            },
                                            ':hover': {
                                                backgroundColor: 'transparent',
                                            },
                                            ':active': {
                                                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                            },}}>
                                No, Deny
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
            <Dialog open={locationPopupOpen} onClose={handleLocationDisagree}>
                {/* <DialogTitle sx={{ fontFamily: 'Poppins' }}>Select Location</DialogTitle> */}
                <DialogContent>
                    <DialogContentText sx={{ mb: 2, fontFamily: 'Poppins', color: COLORS.primaryColor, }}>
                        Select the location
                    </DialogContentText>

                    {/* Displaying the units as clickable buttons */}
                    <Grid container spacing={2}>
                        {dropdownOptions.map(option => (
                            <Grid item xs={12} key={option.id}>
                                <Button
                                    fullWidth
                                    variant={selectedUnit === option.name ? 'contained' : 'outlined'}
                                    // startIcon={<LocationOnIcon sx={{ color: COLORS.primaryColor }} />}
                                    onClick={() => handleUnitSelection(option.name)}
                                    sx={{
                                        fontFamily: 'Poppins',
                                        color: COLORS.textColor,
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        justifyContent: 'flex-start',
                                        // borderRadius: '8px',
                                        border: 'none',
                                        textTransform: 'none',
                                        padding: '2px',
                                        ...(selectedUnit === option.UnitName && {
                                            backgroundColor: COLORS.primaryColor,
                                            color: '#fff',
                                        }),
                                    }}
                                >
                                    {option.name}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default HomePage;
