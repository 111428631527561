import React, { useState, useRef, useEffect } from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    Card,
    Avatar,
    Divider,
    Grid,
    TextField,
    MenuItem,
    IconButton,
    Paper,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { emptyBookingUploadRecord, emptyUploadRecord, myUploadSelectedClear, myUploadBookingSelectedList, onMyUploadBookingSelection } from '../store/actions/recordActions';
import { COLORS } from "../constants/Theme";
import { LuUsers } from "react-icons/lu";
import { FaPencilAlt, FaVideo } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CalendarIcon } from "@mui/x-date-pickers";
import { patientName } from "../store/actions/types";
import { useDispatch, useSelector } from "react-redux";
import { getProfileApi } from '../utils/apiCalls';
import { allowNumOnly, isResponseIsValid, snackBar } from '../utils/helpers';
import LoginDrawer from './LoginDrawer';
import {
    bookingDoctorDetails,
    doctorDetails,
    slotSelectionApi,
    slotSelectionApiBook,
    slotsListApi,
    unitsListApi,
    familyMembersList,
    requestNow
} from '../utils/apiCalls';
import { UploadDuplicateBookingList, UploadFileBookingList, emptyBookingFileUploadedFile, removeBookingFileUploadedFile } from '../store/actions/uploadBookingActions';
import { getSecondaryProfileID, getUserInformation, setPayCheckoutSuccess, setWebHookCheckout } from '../utils/LocalStorage';
import { Base64 } from 'js-base64';
import { currentProfileRawData, familyReloadData } from '../store/actions/homeActions';
import {
    consultMode,
    doctorDetailsItem,
    slotAddress,
    slotAmount,
    slotDetails,
    slotStartTime,
    patient_Name,
    patient_Id, patient_Notes
} from '../store/actions/bookingActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatDateTime } from '../utils/helpers';
import { profileImageSaved } from '../store/actions/authActions';
import { EventEmitter } from 'events';
import {
    bookingInitialize,
    createBooking,
    createBookingPayAtHospital,
    createWebHooks,
} from '../utils/apiCalls';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';

const RequestModal = ({ open, onCloseAppointment, doctor, selectedUnit, unitAddress, unitId, onDrawerOpen, onCloseDrawer, isDopen, }) => {
    const [step, setStep] = useState(1);
    const [selectedDate, setSelectedDate] = useState(new Date());
    // const [slots, setSlots] = useState(["09:00 AM", "10:00 AM", "11:00 AM", "03:00 PM"]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState("");
    const [notes, setNotes] = useState("");
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [consultationType, setConsultationType] = useState("In-person");
    const modalContentRef = useRef(null);
    const [formattedDate, setFormattedDate] = useState('');
    const [formattedDate1, setFormattedDate1] = useState('');
    const [doctorSlot, setDoctorSlot] = useState([]);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [data, setData] = useState([]);
    const [unitsList, setUnitsList] = useState([]);
    const [slots, setSlots] = useState([]);
    const [slot_range, setSlot_range] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [slot_rangeVideo, setSlot_rangeVideo] = useState('');
    const [relationValue, setRelationValue] = useState('');
    const [popupLoading, setPopupLoading] = useState(false);
    const [accordion, setAccordion] = useState('open');
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [offlineSlotDetails, setOfflineSlotDetails] = useState({});
    const [onlineSlotDetails, setOnlineSlotDetails] = useState({});
    const [onSelectedDate, setOnSelectedDate] = useState(new Date());
    const [onSelectedVideoDate, setOnSelectedVideoDate] = useState(new Date());
    const [videoCallApiCalled, setVideoCallApiCalled] = useState(false);
    const [video_retry, setVideo_retry] = useState(false);
    const [dateRange, setDateRange] = useState(generateDateRange(new Date()));
    const [consulting_video_slot, setConsulting_video_slot] = useState([]);
    const [isCheckoutDialogOpen, setCheckoutDialogOpen] = useState(false);
    const [noFessPopup, setNoFessPopup] = useState(false);
    const [open1, setOpen] = useState(false);
    const [isCheckout, setIsCheckout] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isPatientNameValid, setIsPatientNameValid] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [userToken, setUserToken] = useState('');
    const [reloadKey, setReloadKey] = useState(0);
    const [rupees, setRupees] = useState('0.00');
    const [slotItemData, setSlotItemData] = useState({});
    const [dataList, setDataList] = useState({});
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [uhidList, setUhidList] = useState([]);
    const history = useHistory();
    const [doctorId, setDoctorId] = useState('');
    const [relationData, setRelationData] = useState([]);
    const eventEmitter = new EventEmitter();
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [bookingErrorPopup, setBookingErrorPopup] = useState(false);
    const [bookingErrorContent, setBookingErrorContent] = useState('');
    const [openLogin, setOpenLogin] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(open);
    const [requestid, setRequestid] = useState('');
    const [formData, setFormData] = useState({
        relationValue: '',
        notes: '',
    });

    const doctor_id = doctor ? doctor._id.$oid : '';
    const unitName = useSelector(
        state => state?.authReducer?.unitName,
    );

    const slotDetails1 = useSelector(
        // Slot details
        state => state?.bookingReducer?.slotDetails,
    );

    const slotStartTime1 = useSelector(
        // Slot Start time
        state => state?.bookingReducer?.slotStartTime,
    );

    const slotAddress1 = useSelector(
        // Slot address
        state => state?.bookingReducer?.slotAddress,
    );

    const amount = useSelector(
        // Amount
        state => state?.bookingReducer?.amount,
    )

    const patientDetails = useSelector(
        // Patient details
        state => state?.bookingReducer?.patientDetails,
    );

    const doctorDetails = useSelector(
        // Doctor details
        state => state?.bookingReducer?.doctorDetails,
    );

    const profile_information = useSelector(
        state => state?.homeReducer?.profile_info,
    );

    const currentUser = useSelector(
        state => state?.homeReducer?.currentProfileName,
    );

    const uploadedFileList = useSelector(
        state => state?.uploadBookingReducer?.file_uploaded_list,
    );

    const currentProfileData = useSelector(
        state => state?.homeReducer?.currentProfileRawData,
    );
    const token = localStorage.getItem('user_token');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, [slots]);

    useEffect(() => {
        // getUnitsListApi(selectedDate);
        if (step === 1) {
            const formatted = formatDate(selectedDate);
            const formatted1 = formatDate1(selectedDate);
            setFormattedDate(formatted);
            setFormattedDate1(formatted1);
        }
    }, [selectedDate]);

    useEffect(() => {
        if (relationData.length > 0) {
            setRelationValue(relationData[0].name);
            dispatch(patient_Name(relationData[0].name));
            dispatch(patient_Id(relationData[0].app_profile_id));
        }
    }, [relationData]);

    useEffect(() => {
        // if (token) {
        const familyMembersSelectPatientListener = eventEmitter.addListener('familyMembersSelectPatient', (event) => {
            familyMembersApiCall();
            console.log(event, "Event");
            // if (event.isShowPopup) {
            //   setProfileCreatedPopup(event.isShowPopup);
            // }
            if (event.isName) {
                setName(event.isName);
            }
            getUserInformation('User_Data').then(res => {
                let response = JSON.parse(res);
                setUserToken(response.token);
            });
            familyMembersApiCall();
        });

        const sectionListReloadListener = eventEmitter.addListener('SectionListReload', (event) => {
            setTimeout(() => {
                setReloadKey(reloadKey + 1);
            }, 1000);
        });

        getUserInformation('User_Data').then(res => {
            let response = JSON.parse(res);
            setUserToken(response.token);
        });

        familyMembersApiCall();

        return () => {
            // familyMembersSelectPatientListener.remove();
            // sectionListReloadListener.remove();
        };
    }
        , [reloadKey]);

    useEffect(() => {
        dispatch(myUploadSelectedClear());
        dispatch(emptyBookingFileUploadedFile(''));
        dispatch(emptyBookingUploadRecord([]));
        // if (mhc == true) {
        //   getSecondaryProfileID('Secondary_Profile_ID')
        //     .then(res => {
        //       let json = JSON.parse(res);
        //       const relations = currentProfileData;
        //       relations.forEach(item => {
        //         if (item.app_profile_id === json) {

        //           dispatch(patient_Id(item.app_profile_id));
        //           setPaitentId(item.app_profile_id)
        //         }
        //       });
        //     })
        // }
    }, [])

    const handleRelationChange = (event) => {
        setRelationValue(event.target.value);
        setIsPatientNameValid(true);
    };

    const familyMembersApiCall = async () => {

        try {
            const response = await familyMembersList();
            if (isResponseIsValid(response)) {
                console.log(JSON.stringify(response.data.data));
                setRelationData(response.data.data);
                dispatch(currentProfileRawData(response.data.data));
                setTimeout(() => {

                }, 400);
            } else {
                setTimeout(() => {
                    snackBar(JSON.stringify(response.data));

                }, 400);
            }
        } catch (err) {
            setTimeout(() => {
                snackBar(JSON.stringify(err));

            }, 400);
        }
    };

    useEffect(() => {
        // setNameErrorState(false);
        dispatch(myUploadBookingSelectedList([]));
        dispatch(emptyBookingFileUploadedFile(''));
    }, [patientName]);

    function generateDateRange(startDate) {
        const dates = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(startDate);
            date.setDate(startDate.getDate() + i);
            dates.push(date);
        }
        return dates;
    }

    const handleFileUpload1 = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to array
        if (files.length) {
            setUploadedFile(files); // Set multiple files
        }
    };
    console.log('fffffi', uploadedFile);
    const handleCalendarClose = () => {
        setOpen(false);
    };

    const handleCalendarDateChange = (event) => {
        const newDate = new Date(event.target.value);
        setSelectedDate(newDate);
        setDateRange(generateDateRange(newDate));
        setOpen(false);
    };

    const handleNext1 = () => {
        // setIsFormEditable(false);
        // setIsFormFilled(true);
        // Capture the filled form data
        // setFormData({
        //     relationValue,
        //     notes,
        //     uploadedFile,
        //     amount,
        // });

        setIsCheckout(true);

    };

    const handleCheckout = () => {
        // setCheckoutDialogOpen(true);
        // const queryParams = new URLSearchParams({
        //   selectedUnit: selectedUnit,
        //   dropdownOptions: dropdownOptions,
        //   item: JSON.stringify(item),
        //   data: JSON.stringify(data),
        //   doctorSlot: JSON.stringify(doctorSlot),
        //   time: time,
        //   fees: fees,
        //   pname: name,
        //   notes: notes,
        //   unitAddress: unitAddress
        // }).toString();

        // history.push(`/checkout?${queryParams}`);

        onBookingCheckoutEvent()
        // radioButtonRef.current.open();
        onCheckoutApiCall('', false);
    };

    const onCheckoutApiCall = async (hms_id, is_new_user) => {
        setLoader(true);
        const body = {
            // unit_id:slotDetails?.unit_id?.$oid,
            unit_id:
                slotDetails1?.slot_type === 'Offline'
                    ? slotAddress1.unit_id
                    : slotDetails1?.unit_id,
            // slot_id: slotDetails?._id.$oid,
            doctor_id: doctorDetails?.doctor_id,
            patient_id: patientDetails?.patient_id,
            notes: notes,
            booking_type: slotDetails1?.slot_type,
            consult_date: `${slotDetails1?.slot_date + 'T' + slotStartTime1 + ':00'}`,
            consult_time: `${slotDetails1?.slot_date + 'T' + slotStartTime1 + ':00'}`,
            amount: `${amount}`,
            patient_name: patientDetails?.patient_name,
            ref_time: slotStartTime1,
            hms_id: hms_id,
            is_new_user: is_new_user,
            files: uploadedFileList ? uploadedFileList : '',
            // `${2023-08-06T05:37:58}`
            // "2028-08-03T08:01:01"
        };
        try {
            console.log(body, 'CHECKOUT BODY');
            const response = await createBooking(body);
            console.log(response, 'CHECKOUT response');
            if (isResponseIsValid(response)) {
                console.log(response, 'response');

                if (response?.data?.kh_user_data) {
                    // setCheckoutPressed(true);
                    console.log('kh_user_data response', response.data.kh_user_data);
                    setUhidList(response.data.kh_user_data);
                    setTimeout(() => {
                        // setLoader(false);
                        // radioButtonRef.current.open();
                    }, 500);
                } else {
                    setTimeout(() => {
                        // mixpanel.timeEvent(mixpanel_event.BOOKING_PAYMENT_SESSION)
                        //setLoader(false);
                        onCallInitialize(response?.data?._id, hms_id);
                    }, 500);
                }

                // onCallInitialize(response?.data?._id);
            } else {
                setLoader(false);
                console.log(response?.data?.message, 'BOOKING ERROR');
                if (response?.data?.message) {
                    setBookingErrorContent(response?.data?.message);
                    setTimeout(() => {
                        setBookingErrorPopup(true);
                    }, 400);
                } else {
                    setTimeout(() => {
                        snackBar(Headers.apiError);
                    }, 500);
                }
                // setLoader(false);
                // setTimeout(() => {
                //   if (response?.data?.message) {
                //     snackBar(JSON.stringify(response?.data?.message));
                //   } else {
                //     snackBar(Headers.apiError);
                //   }
                // }, 400);
            }
        } catch (err) {
            setLoader(false);
            setTimeout(() => {
                snackBar(JSON.stringify(err));
            }, 400);
        }
    };

    const onCallInitialize = async (id, hms_id) => {
        try {
            const response = await bookingInitialize(id);
            if (isResponseIsValid(response)) {
                console.log('RESP', response.data);
                const mid = Base64.decode(response.data.emid);
                console.log('MID ----->>>>', mid);
                const call_back_url = response.data.call_back_url;
                const order_id = response.data.order_id;
                const tranxToken = response.data.token;
                const am = `${amount}`;

                onPayTmCall(order_id, mid, tranxToken, am, call_back_url, hms_id);

            } else {
                handleError(response?.data?.message || Headers.apiError);
            }
        } catch (err) {
            snackBar(JSON.stringify(err));
        }
    };

    // const onCallInitialize = async (id, hms_id) => {
    //   try {
    //     const response = await bookingInitialize(id);
    //     if (isResponseIsValid(response)) {
    //       console.log('RESP', response.data);
    //       const mid = Base64.decode(response.data.emid);
    //       console.log('MID ----->>>>', mid);
    //       const call_back_url = response.data.call_back_url;
    //       const order_id = response.data.order_id;
    //       const tranxToken = response.data.token;
    //       const am = `${amount}`;
    //       setTimeout(() => {
    //         onPayTmCall(order_id, mid, tranxToken, am, call_back_url, hms_id);
    //       }, 500);
    //     } else {
    //       console.log(response?.data?.message, 'BOOKING INITIALIZE ERROR');
    //       if (response?.data?.message) {
    //         setTimeout(() => {
    //           snackBar(response?.data?.message);
    //         }, 200);
    //       } else {
    //         setTimeout(() => {
    //           snackBar(Headers.apiError);
    //         }, 500);
    //       }
    //     }
    //   } catch (err) {
    //     setTimeout(() => {
    //       snackBar(JSON.stringify(err));
    //     }, 400);
    //   }
    // };

    // const onPayTmCall = (orderId, mid, txnToken, amount, callbackUrl, hms_id) => {
    //   const config = {
    //     root: "",
    //     flow: "DEFAULT",
    //     data: {
    //       orderId: orderId,
    //       token: txnToken,
    //       tokenType: "TXN_TOKEN",
    //       amount: amount
    //     },
    //     handler: {
    //       transactionStatus: function(status) {
    //         console.log("Transaction Status:", status);

    //         const payload = {
    //           paytm_response: status,
    //           hms_id: hms_id,
    //         };

    //         webHooksCall(payload); // Call your webhook with the response
    //         console.log(payload, 'WEBHOOK PAYLOAD');
    //       },
    //       notifyMerchant: function(eventName, data) {
    //         console.log("notifyMerchant", eventName, data);
    //       }
    //     },
    //     merchant: {
    //       redirect: false
    //     },
    //   };

    //   window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
    //     window.Paytm.CheckoutJS.invoke();
    //   }).catch(function onError(error) {
    //     console.log("Error invoking Paytm CheckoutJS", error);
    //   });
    // };

    // const webHooksCall = async (body) => {
    //   setCheckoutLoader(true);
    //   try {
    //     const response = await createWebHooks(body);
    //     if (isResponseIsValid(response)) {
    //       console.log('Reep', response.data);
    //       setCheckoutLoader(false);
    //       setTimeout(() => {
    //         const success_payload = {
    //           isShowPopup: true,
    //           item: response.data,
    //           doctor_id: item?._id.$oid,
    //           type: 'booking',
    //         };
    //         if (response.data.status === 'approved') {
    //           console.log('Payment done');
    //         }
    //         console.log('success_payload', success_payload);
    //         setIsCheckout(true);
    //         history.push('/');
    //       }, 200);
    //     } else {
    //       setCheckoutLoader(false);
    //       const pay_load = {
    //         isShowPopup: true,
    //         item: 'pending',
    //         type: 'booking',
    //       };
    //       setTimeout(() => {
    //         history.push('/');
    //       }, 200);
    //     }
    //   } catch (err) {
    //     setTimeout(() => {
    //       setCheckoutLoader(false);
    //       snackBar(JSON.stringify(err));
    //     }, 400);
    //   }
    // };

    const onPayTmCall = (orderId, mid, txnToken, amount, callbackUrl, hms_id) => {
        const config = {
            root: "",
            flow: "DEFAULT",
            data: {
                orderId: orderId,
                token: txnToken,
                tokenType: "TXN_TOKEN",
                amount: amount
            },
            handler: {
                transactionStatus: function (status) {
                    console.log("Transaction Status:", status);

                    const payload = {
                        paytm_response: status,
                        hms_id: hms_id,
                    };

                    // Call your webhook with the response
                    webHooksCall(payload);
                    console.log(payload, 'WEBHOOK PAYLOAD');
                },
                notifyMerchant: function (eventName, data) {
                    console.log("Notify Merchant:", eventName, data);
                }
            },
            merchant: {
                redirect: true, // Redirect user after payment
                redirectUrl: callbackUrl // Specify the callback URL
            },

        };


        // Initialize and invoke the Paytm payment process
        if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.init(config)
                .then(() => {
                    window.Paytm.CheckoutJS.invoke();
                })
                .catch(error => console.error("Error invoking Paytm CheckoutJS:", error));
        } else {
            console.error("Paytm CheckoutJS is not loaded or available");
        }
    };

    const webHooksCall = async (payload) => {
        try {
            const response = await createWebHooks(payload);
            if (isResponseIsValid(response)) {
                console.log('Reep', response.data);
                setCheckoutLoader(false);
                setTimeout(() => {
                    let success_payload = {
                        isShowPopup: true,
                        item: response.data,
                        doctor_id: doctorDetails?.doctor_id,
                        type: 'booking'
                    }
                    if (response.data.status === 'approved') {
                        onMakePaymentEvent('Payment done');
                    }
                    console.log('success_payload', success_payload)
                    setPayCheckoutSuccess('pay_success', JSON.stringify(success_payload));
                    // navigation.navigate('HomeScreen', { screen: 'Home' });
                    history.push('/');
                }, 200);
            } else {
                setCheckoutLoader(false);
                console.log(JSON.stringify(response), 'WEBHOOKS ERROR');
                // DeviceEventEmitter.emit('webHookData', {
                //   isShowPopup: true,
                //   item: 'pending',
                //   type: 'booking'
                // });
                let pay_load = {
                    isShowPopup: true,
                    item: 'pending',
                    type: 'booking'
                }
                setWebHookCheckout('webhook_popup', JSON.stringify(pay_load))
                setTimeout(() => {
                    // navigation.navigate('HomeScreen', { screen: 'Home' });
                    //   navigation.navigate('Home');
                    history.push('/');
                }, 200);
                // snackBar('Paytm error');
                // console.log('Paytm error')
                // setTimeout(() => {
                //   setCheckoutLoader(false);
                // }, 400);
            }
        } catch (err) {
            setTimeout(() => {
                setCheckoutLoader(false);
                snackBar(JSON.stringify(err));
            }, 400);
        }
    };


    const handleError = (message) => {
        setTimeout(() => {
            snackBar(message);
        }, 200);
    };

    const handleSuccess = (data) => {
        setCheckoutLoader(false);
        const success_payload = {
            isShowPopup: true,
            item: data,
            doctor_id: doctor._id.$oid,
            type: 'booking',
        };
        if (data.status === 'approved') {
            console.log('Payment done');
        }
        setIsCheckout(true);
        history.push('/');
    };

    const handlePending = () => {
        setCheckoutLoader(false);
        const pay_load = {
            isShowPopup: true,
            item: 'pending',
            type: 'booking',
        };
        history.push('/');
    };

    const onBookingCheckoutEvent = () => {
        let profile_info = profile_information;
        let property_input = {
            "Mobile number": profile_info.mobile_number,
            "Age": profile_info.age,
            "Gender": profile_info.gender,
            "Relationship": profile_info.relationship,
            "Is Primary User": profile_info.is_primary_user
        }
        // mixpanel.track(mixpanel_event.CHECKOUT, property_input)
    }

    const handlePayatHospital = () => {
        onBookingPayatHospitalEvent();
        onCheckoutPayAtHospitalApiCall('', false);

    };

    const onBookingPayatHospitalEvent = () => {
        let profile_info = profile_information;
        let property_input = {
            "Mobile number": profile_info.mobile_number,
            "Age": profile_info.age,
            "Gender": profile_info.gender,
            "Relationship": profile_info.relationship,
            "Is Primary User": profile_info.is_primary_user
        }
        // mixpanel.track(mixpanel_event.CHECKOUT_PAY_AT_HOSPITAL, property_input)
    }

    const onCheckoutPayAtHospitalApiCall = async (hms_id, is_new_user) => {
        // setLoader(true);
        setCheckoutLoader(true);
        const body = {
            // unit_id:slotDetails?.unit_id?.$oid,
            unit_id:
                slotDetails1?.slot_type === 'Offline'
                    ? slotAddress1.unit_id
                    : slotDetails1?.unit_id,
            // slot_id: slotDetails?._id.$oid,
            doctor_id: doctorDetails?.doctor_id,
            patient_id: patientDetails?.patient_id,
            notes: patientDetails?.notes,
            booking_type: slotDetails1?.slot_type,
            consult_date: `${slotDetails1?.slot_date + 'T' + slotStartTime1 + ':00'}`,
            consult_time: `${slotDetails1?.slot_date + 'T' + slotStartTime1 + ':00'}`,
            amount: `${amount}`,
            patient_name: patientDetails?.patient_name,
            ref_time: slotStartTime1,
            hms_id: hms_id,
            is_new_user: is_new_user,
            files: uploadedFileList ? uploadedFileList : ''
        };
        console.log(body, 'BOOKING CREATE BODY PAY AT HOS');
        try {
            console.log(body, 'BOOKING CREATE BODY PAY AT HOS');
            const response = await createBookingPayAtHospital(body);
            console.log(
                JSON.stringify(response),
                'createBookingPayAtHospital RESPONSE',
            );
            if (isResponseIsValid(response)) {
                // setLoader(false);
                if (response?.data?.kh_user_data) {
                    // setCheckoutPressed(false);
                    console.log(response.data.kh_user_data, 'response');
                    setUhidList(response.data.kh_user_data);
                    setTimeout(() => {
                        setCheckoutLoader(false);
                        // radioButtonRef.current.open();
                    }, 500);
                } else {
                    setCheckoutLoader(false);
                    setTimeout(() => {
                        // DeviceEventEmitter.emit('checkoutData', {
                        //   isShowPopup: true,
                        //   item: response.data,
                        //   type: 'booking'
                        // });
                        if (response.data.status === 'approved') {
                            onMakePaymentEvent('Pay at hospital');
                        }
                        let success_payload = {
                            isShowPopup: true,
                            item: response.data,
                            type: 'booking'
                        }
                        console.log('success_payload', success_payload)
                        setPayCheckoutSuccess('pay_success', JSON.stringify(success_payload));
                        setCheckoutDialogOpen(true);


                    }, 200);
                }
            } else {
                // setLoader(false);
                setCheckoutLoader(false);
                // console.log(response?.data?.message, 'BOOKING ERROR');
                if (response?.data?.message) {
                    setBookingErrorContent(response?.data?.message);
                    setTimeout(() => {
                        setBookingErrorPopup(true);
                    }, 400);
                } else {
                    // DeviceEventEmitter.emit('webHookData', {
                    //   isShowPopup: true,
                    //   item: 'pending',
                    //   type: 'booking'
                    // });
                    let pay_load = {
                        isShowPopup: true,
                        item: 'pending',
                        type: 'booking'
                    }
                    setWebHookCheckout('webhook_popup', JSON.stringify(pay_load))
                    setTimeout(() => {
                        history.push('/');
                    }, 500);
                }
                // setLoader(false);
                // setTimeout(() => {
                //   if (response?.data?.message) {
                //     snackBar(JSON.stringify(response?.data?.message));
                //   } else {
                //     snackBar(Headers.apiError);
                //   }
                // }, 400);
            }
        } catch (err) {
            console.log('catch checkout error', err)
            // setLoader(false);
            setCheckoutLoader(false);
            setTimeout(() => {
                snackBar(JSON.stringify(err));
            }, 400);
        }
    };

    const onMakePaymentEvent = (payment_option) => {
        let profile_info = profile_information;
        let property_input = {
            "Mobile number": profile_info.mobile_number,
            "Age": profile_info.age,
            "Gender": profile_info.gender,
            "Relationship": profile_info.relationship,
            "Is Primary User": profile_info.is_primary_user,
            "Consultation type": slotDetails1?.slot_type, //Online/Offline
            "Payment amount": amount,
            //  "Doctor name": doctorDetails?.first_name,
            //   "Speciality": doctorDetails?.default_specialization,
            "Hospital Location": slotAddress1?.address,
            "Hospital unit": slotAddress1?.name,
            "Date selected": formatDateTime(slotDetails1?.slot_date, slotStartTime1),
            "Time slot": slotStartTime1,
            "Patient name": patientDetails?.patient_name,
            "Payment option": payment_option
        }
        console.log('on confirmation booking-+->', property_input)
        // mixpanel.track(mixpanel_event.CONFIRMATION, property_input)
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCloseCheckoutDialog1 = () => {
        // setCheckoutDialogOpen(false);
        setBookingErrorPopup(false);
    };

    const handleCloseCheckoutDialog = () => {
        setCheckoutDialogOpen(false);
        // setBookingErrorPopup(false);
        history.push('/');
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
        dispatch(patient_Notes(event.target.value));
    };

    const handleMenuItemClick = (item) => {
        console.log('notessssss', item);
        setName(item.name);
        dispatch(patient_Name(item.name));
        dispatch(patient_Id(item.app_profile_id));
        // dispatch(patient_Name(item.name));
        setIsPatientNameValid(false);
    };

    // const handleInperson = () => {
    //     console.log('hhhhhh');
    //     setConsultationType("In-person");
    //     setStep(2);
    //     getUnitsListApi(selectedDate);
    //     // onCloseAppointment();
    //     setOpenLogin(true);
    // }
    const handleInperson = () => {
        if (!name) {
            setConsultationType("In-person");
            setStep(2);
            getUnitsListApi(selectedDate); // Assuming this triggers an API call
            setOpenLogin(true);
            setIsModalOpen(false);// Open the login modal or any other modal
        }
        else {
            setConsultationType("In-person");
            setStep(2);
            getUnitsListApi(selectedDate); // Assuming this triggers an API call
            // setOpenLogin(true); 
            // setIsModalOpen(false);
        }
    }

    useEffect(() => {
        console.log('opnnnnn', token);
        // if (token) {
        onCallProfileApi();
        // upcomingEventsApiCall();
        // }
    }, []);

    const onCallProfileApi = async () => {
        const response = await getProfileApi();
        console.log('rrr', response);
        if (isResponseIsValid(response)) {
            dispatch(profileImageSaved(response.data.photo));
            setData(response.data);
            setName(response.data.name);
        }
    };
    console.log('nnnnn', data)
    const patients = ["John Doe", "Jane Smith", "Emily Johnson"]; // Example dropdown options

    useEffect(() => {
        if (modalContentRef.current) {
            // Scroll to the bottom when the selectedSlot changes
            modalContentRef.current.scrollTo({
                top: modalContentRef.current.scrollHeight,
                behavior: "smooth", // Smooth scrolling effect
            });
        }
    }, [selectedSlot]);

    const getUnitsListApi = async (date) => {
        setLoadingData(true);
        try {
            const response = await unitsListApi(doctor_id, unitId);
            if (isResponseIsValid(response)) {
                console.log('UNITS LIST', JSON.stringify(response.data));

                const units_list_data = response.data;
                setUnitsList(units_list_data.data.units);
                setDoctorId(units_list_data.data.units[0].doctor_id);
                console.log('DOC ID', units_list_data.data.units[0].doctor_id);
                const docId = units_list_data.data.units[0].doctor_id;
                setTimeout(() => {
                    getSlotsApi(date, false, docId);
                }, 200)
            } else {
                setTimeout(() => {
                    setLoadingData(false);
                }, 500);
                console.log('UNITS LIST ERROR', JSON.stringify(response.data));
            }
        } catch (error) {
            setTimeout(() => {
                setLoadingData(false);
            }, 500);
            console.error(error);
        }
        // setUnitsList(units_list_data.data.units);
        return new Promise(resolve => setTimeout(resolve, 1000));
    };

    const getSlotsApi = async (date, popupLoading = false, doctor_id, index = 0, clickable = false) => {
        setPopupLoading(popupLoading);
        const sd = moment(date).format('YYYY-MM-DD');
        setOnSelectedDate(sd);
        try {
            const body = {
                doctor_id: doctor_id,
                slot_type: 'Offline',
                date: sd,
            };
            console.log('Slots Request body', body);
            const response = await slotsListApi(body);
            console.log('SLOT LIST RESPONSE', JSON.stringify(response));
            if (isResponseIsValid(response)) {
                console.log('SLOT LIST', JSON.stringify(response.data));
                const slot_list_data = response.data;
                setSlot_range(slot_list_data.data.consult_range);
                setSlots(slot_list_data.data.timings);
                setUnitsList(prevUnitsList => {
                    const updatedUnitsList = [...prevUnitsList];
                    console.log('SLOT LIST INDEX', updatedUnitsList[index]);

                    if (updatedUnitsList[index]) {
                        updatedUnitsList[index].is_loading = false;
                        updatedUnitsList[index].is_opened = true;
                        setAccordion('open')
                    }
                    if (clickable) {
                        updatedUnitsList.forEach((it, i) => {
                            if (i != index) {
                                it.is_opened = false;
                                setAccordion('close')
                            }
                        });
                        setTimeout(() => {
                            //  alert(index)
                            // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                            // scrollToIndex(index)
                        }, 500);
                    }

                    let slot_details = {
                        unit_id: updatedUnitsList[index].unit_id,
                        slot_type: "Offline",
                        slot_date: sd
                    }

                    setOfflineSlotDetails(slot_details);

                    dispatch(slotDetails(slot_details));

                    return updatedUnitsList;
                });
                setLoadingData(false);
                // setTimeout(() => {
                //   setLoadingData(false);
                // }, 200);
                if (popupLoading == true) {
                    setPopupLoading(false);
                    //  setShowIndicator(true);

                } else {
                    setTimeout(() => {
                        // setShowIndicator(true);
                    }, 400);
                }
            }
            else {
                setUnitsList(prevUnitsList => {
                    const updatedUnitsList = [...prevUnitsList];
                    if (updatedUnitsList[index]) {
                        updatedUnitsList[index].is_loading = false;
                    }
                    return updatedUnitsList;
                });
                snackBar(`We're experiencing high traffic! Please try again shortly `);
                setTimeout(() => {
                    setLoadingData(false);
                }, 400);

                if (popupLoading == true) {
                    setTimeout(() => {
                        setPopupLoading(false);
                        // setShowIndicator(true);
                    }, 200);
                } else {
                    setTimeout(() => {
                        // setShowIndicator(true);
                    }, 400);
                }
            }
        } catch (error) {
            setUnitsList(prevUnitsList => {
                const updatedUnitsList = [...prevUnitsList];
                if (updatedUnitsList[index]) {
                    updatedUnitsList[index].is_loading = false;
                }
                return updatedUnitsList;
            });
            setTimeout(() => {
                setLoadingData(false);
            }, 500);
            if (popupLoading == true) {
                setTimeout(() => {
                    setPopupLoading(false);
                    // setShowIndicator(true);
                }, 200);
            } else {
                setTimeout(() => {
                    // setShowIndicator(true);
                }, 400);
            }
            console.error(error);
        }
    };

    const getVideoSlotsApi = async (date) => {
        setPopupLoading(true);
        const sd = moment(date).format('YYYY-MM-DD');
        setOnSelectedVideoDate(sd);
        try {
            const body = {
                doctor_id: doctor_id,
                slot_type: 'Online',
                date: sd,
            };
            console.log('Request body video', body);
            const response = await slotsListApi(body);
            console.log('VIDEO SLOT RESPONSE', JSON.stringify(response));
            if (isResponseIsValid(response)) {
                setVideoCallApiCalled(true);
                setVideo_retry(false);
                const video_slot_list_data = response.data;
                setSlot_rangeVideo(video_slot_list_data.data.consult_range);
                console.log('video_slot_list_data.data.timings', video_slot_list_data.data.timings);
                setConsulting_video_slot(video_slot_list_data.data.timings);

                let slot_details = {
                    unit_id: unitId,
                    slot_type: "Online",
                    slot_date: sd
                }

                setOnlineSlotDetails(slot_details);

                dispatch(slotDetails(slot_details));

                setTimeout(() => {
                    setPopupLoading(false);
                }, 200);
            } else {
                setVideo_retry(true);
                setVideoCallApiCalled(true);
                setSlot_rangeVideo([]);
                setConsulting_video_slot([]);
                setTimeout(() => {
                    setPopupLoading(false);
                    // setShowIndicator(true);
                }, 200);
            }
        } catch (error) {
            setTimeout(() => {
                setPopupLoading(false);
                //setShowIndicator(true);
            }, 200);
            console.error(error);
        }
    };

    const handleDateChange = (direction) => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + (direction === "next" ? 1 : -1));
        setSelectedDate(newDate);
        setSlots([]);
        setRupees('0.00');
        const newFormattedDate = formatDate(newDate);
        setFormattedDate(newFormattedDate);

        const unitsWithState = unitsList.map((unit, index) => ({
            ...unit,
            is_opened: false,
        }));

        setUnitsList(unitsWithState);
        setDoctorId(unitsList[0].doctor_id);
        getSlotsApi(newDate, true, unitsList[0].doctor_id);

    };

    const handleDateChange1 = (direction) => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + (direction === "next" ? 1 : -1));
        setSelectedDate(newDate);
        setConsulting_video_slot([]);
        setRupees('0.00');
        const newFormattedDate1 = formatDate1(newDate);
        setFormattedDate1(newFormattedDate1);
        const unitsWithState = unitsList.map((unit, index) => ({
            ...unit,
            is_opened: false,
        }));

        setUnitsList(unitsWithState);
        setDoctorId(unitsList[0].doctor_id);
        getVideoSlotsApi(newDate, true, unitsList[0].doctor_id);

    };

    const formatDate = (date) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
    };

    const formatDate1 = (date) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    };


    const handleNext = () => {
        // setIsFormEditable(false);
        // setIsFormFilled(true);
        // Capture the filled form data
        setFormData({
            relationValue,
            notes,
        });

        onRequestNowApiCall();
    };

    const onRequestNowApiCall = async () => {
        setLoader(true);
        const body = {
            doctor_id: doctor?._id.$oid,
            patient_id: patientDetails?.patient_id,
            notes: notes,
            unit_name: unitName,
        };
        try {
            console.log(body, 'REQUEST NOW BODY');
            const response = await requestNow(body);
            console.log(response, 'Request Response');
            if (isResponseIsValid(response)) {
                setTimeout(() => {
                    setLoader(false);
                    eventEmitter.emit('requestNowData', {
                        isShowPopup: true,
                        item: response.data,
                    });
                    setMessage(JSON.stringify(response?.data?.message));
                    setRequestid(JSON.stringify(response?.data?.request_id));
                    setCheckoutDialogOpen(true);
                    //   history.push('/');
                }, 200);
            } else {
                setLoader(false);
                setTimeout(() => {
                    if (response?.data?.message) {
                        snackBar(JSON.stringify(response?.data?.message));
                        setMessage(JSON.stringify(response?.data?.message));
                        // setRequestid(JSON.stringify(response?.data?.request_id));
                        setBookingErrorPopup(true);
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setLoader(false);
            setTimeout(() => {
                snackBar(JSON.stringify(err));
            }, 400);
        }
    };
    // const handleNext = () => {
    //     // if(!name)
    //     if (!name) {
    //       history.push('/signup');
    //     }
    //     else {
    //       const updatedDataList = {
    //         ...dataList,
    //         slot_range: slot_range,
    //         doctor_id: doctorId,
    //       };

    //       console.log('updatedDataList', updatedDataList);

    //       // Dispatch the updatedDataList
    //       dispatch(doctorDetailsItem(updatedDataList));

    //       dispatch(slotDetails(offlineSlotDetails));
    //       dispatch(slotAmount(rupees));
    //       dispatch(consultMode(page === 1 ? 'Offline' : 'Online'));
    //       const queryParams = new URLSearchParams({
    //         selectedUnit: selectedUnit,
    //         dropdownOptions: JSON.stringify(dropdownOptions),
    //         item: JSON.stringify(item),
    //         data: JSON.stringify(data),
    //         doctorSlot: JSON.stringify(doctorSlot),
    //         time: selectedSlot,
    //         fees: amount,
    //         unitAddress: unitAddress,
    //         date: formattedDate1,
    //         page: page
    //       }).toString();

    //       history.push(`/selectpatient?${queryParams}`);
    //     }
    //   };

    const handleBack = () => setStep((prev) => prev - 1);
    const handleClose = () => {
        setOpenLogin(false);
    };

    const handleYes = () => {
        console.log('User signed out');
        setOpenLogin(false); // Close the current modal
        // onCloseAppointment(); // Notify the other page to close its modal
        setTimeout(() => {
            setIsDrawerOpen(true); // Open LoginDrawer after ensuring the previous modal closes
        }, 0);
        // Add your sign-out logic here
    };
    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };


    return (
        <Box>
            <Modal open={isModalOpen} onClose={onCloseAppointment}
                sx={{
                    display: "flex",
                    alignItems: "center", // Centers vertically
                    justifyContent: "center", // Centers horizontally
                }}>
                <Box
                    ref={modalContentRef}
                    sx={{
                        // position: "absolute",
                        // top: "50%",
                        // left: "50%",
                        // overflowY: "auto",
                        // transform: "translate(-50%, -50%)",
                        // width: { xs: "100%", md: "700px" },
                        // bgcolor: "#fff",
                        // borderRadius: "16px",
                        // boxShadow: 24,
                        // p: 3,
                        width: "100%", // Adjust width as needed
                        maxWidth: "700px", // Limit max width
                        maxHeight: "90vh", // Limit height to enable scrolling
                        overflowY: "auto", // Enable vertical scrolling
                        backgroundColor: "#fff", // Set modal background color
                        borderRadius: "8px", // Add rounded corners
                        p: 3, // Add padding
                        boxShadow: 24, // Add shadow for elevation
                        outline: "none", // Remove default outline
                    }}
                >
                    {/* Header Section */}
                    <Box display="flex" alignItems="center" gap={2} mb={2}>
                        <Avatar
                            sx={{
                                width: 64,
                                height: 68,
                                backgroundColor: "#f8f8f8",
                            }}
                            src={doctor.photo}
                        >
                        </Avatar>
                        <Box>
                            <Typography
                                variant="h7"
                                sx={{
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                    color: COLORS.textColor,
                                }}
                            >
                                {doctor.first_name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color={COLORS.placeholderColor}
                                sx={{ fontFamily: "Poppins" }}
                            >
                                {doctor.default_specialization}
                            </Typography>
                        </Box>
                        <IconButton onClick={onCloseAppointment} sx={{
                            color: COLORS.primaryColor, ml: 40, ':active': {
                                boxShadow: `0 0 0 2px transparent`, outline: 'none', border: '#fff'
                            },
                        }}>
                            <CloseIcon color={COLORS.primaryColor} />
                        </IconButton>
                    </Box>

                    {/* Navigation Status Bar */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <Box
                            sx={{
                                width: "30%",
                                height: "4px",
                                bgcolor: step >= 1 ? COLORS.primaryColor : "#e0e0e0",
                                borderRadius: "2px",
                            }}
                        />
                        <Box
                            sx={{
                                width: "30%",
                                height: "4px",
                                bgcolor: step >= 2 ? COLORS.primaryColor : "#e0e0e0",
                                borderRadius: "2px",
                            }}
                        />
                        <Box
                            sx={{
                                width: "30%",
                                height: "4px",
                                bgcolor: step === 3 ? COLORS.primaryColor : "#e0e0e0",
                                borderRadius: "2px",
                            }}
                        />
                    </Box>

                    {/* Step 3: Patient Details, Notes, and Document Upload */}
                    {step === 1 &&
                        <>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    mb: 2,
                                    fontFamily: "Poppins",
                                    color: COLORS.textColor,
                                }}
                            >
                                Patient Details
                            </Typography>
                            <TextField
                                select
                                label="Select Patient"
                                value={relationValue}
                                onChange={handleRelationChange}
                                // onChange={(e) => setSelectedPatient(e.target.value)}
                                fullWidth
                                sx={{
                                    mb: 2,
                                    "& .MuiInputLabel-root": {
                                        color: COLORS.placeholderColor,
                                        fontFamily: "Poppins"// Default label color
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: COLORS.placeholderColor,
                                        fontFamily: "Poppins"// Label color when focused
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: COLORS.placeholderColor,
                                        fontFamily: "Poppins"// Label color when disabled
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        fontFamily: "Poppins", // Apply font to input
                                        "& fieldset": {
                                            borderColor: 'transparent',
                                            boxShadow: 2
                                        },
                                        "&:hover fieldset": {
                                            borderColor: 'transparent',
                                            boxShadow: 2
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: 'transparent',
                                            boxShadow: 2
                                        },
                                    },
                                    "& .MuiInputBase-input": {
                                        fontFamily: "Poppins", // Ensure text inside input uses the font
                                    },
                                }}
                            >
                                {relationData.map((relation, index) => (
                                    <MenuItem key={index} value={relation} onClick={() => handleMenuItemClick(relation)} sx={{ fontFamily: 'Poppins' }}>
                                        {relation.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Notes"
                                multiline
                                rows={4}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                fullWidth
                                sx={{
                                    mb: 2,
                                    "& .MuiInputLabel-root": {
                                        color: COLORS.placeholderColor,
                                        fontFamily: "Poppins"// Default label color
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: COLORS.placeholderColor,
                                        fontFamily: "Poppins"// Label color when focused
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: COLORS.placeholderColor,
                                        fontFamily: "Poppins" // Label color when disabled
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        fontFamily: "Poppins", // Apply font to input
                                        "& fieldset": {
                                            borderColor: 'transparent',
                                            boxShadow: 2
                                        },
                                        "&:hover fieldset": {
                                            borderColor: 'transparent',
                                            boxShadow: 2
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: 'transparent',
                                            boxShadow: 2
                                        },
                                    },
                                    "& .MuiInputBase-input": {
                                        fontFamily: "Poppins", // Ensure text inside input uses the font
                                    },
                                }}
                            />
                            {relationValue &&
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{
                                        bgcolor: COLORS.primaryColor,
                                        color: "#fff",
                                        textTransform: "none",
                                        ml: 2,
                                        width: '30%',
                                        ':focus': {
                                            outline: 'none',
                                            boxShadow: `0 0 0 2px transparent`,
                                            borderColor: '#fff'
                                        },
                                        ':hover': {
                                            // backgroundColor: '#fff',
                                            outline: 'none',
                                            borderColor: '#fff'
                                        },
                                        ':active': {
                                            boxShadow: `0 0 0 2px #fff`,
                                            outline: 'none',
                                            borderColor: '#fff'
                                        },
                                    }}
                                >
                                    Confirm Request
                                </Button>
                            } </>
                    }

                    <Box display="flex" justifyContent="center" mt={3}>
                        {step >= 1 && step < 4 && (
                            <Button
                                variant="outlined"
                                onClick={handleBack}
                                sx={{
                                    color: COLORS.primaryColor,
                                    borderColor: COLORS.primaryColor,
                                    textTransform: "none",
                                    width: '30%',
                                    ':focus': {
                                        outline: 'none',
                                        boxShadow: `0 0 0 2px transparent`,
                                        borderColor: '#fff'
                                    },
                                    ':hover': {
                                        // backgroundColor: '#fff',
                                        outline: 'none',
                                        borderColor: '#fff'
                                    },
                                    ':active': {
                                        boxShadow: `0 0 0 2px #fff`,
                                        outline: 'none',
                                        borderColor: '#fff'
                                    },
                                }}
                            >
                                Back
                            </Button>
                        )}

                    </Box>
                </Box>
            </Modal>
            <Dialog open={isCheckoutDialogOpen} onClose={handleCloseCheckoutDialog}>

                {/* <IconButton sx={{ fontSize: 48 }}>
                      <CheckCircleIcon sx={{ fontSize: 'inherit', color: 'success.main', mt: '20px' }} />
                    </IconButton> */}
                <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Poppins', mb: 1, color: COLORS.textColor }}>
                    Ticket Id: {requestid}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
                        A ticket is generated to book your appointment. We will notify you shortly after confirmation.
                    </Typography>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button onClick={handleCloseCheckoutDialog} variant="contained" sx={{
                            backgroundColor: '#962067',
                            color: '#fff',
                            p: 1,
                            borderRadius: 8,
                            textTransform: 'none',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 2,
                            width: '30%',
                            '&:hover': {
                                backgroundColor: '#962067',
                                borderColor: '#ffe6f2',
                            },
                            ':focus': {
                                outline: 'none',
                                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                            },
                            // ':hover': {
                            //   backgroundColor: COLORS.primaryColor,
                            // },
                            ':active': {
                                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
                            },
                        }}>Okay</Button>
                    </DialogActions>
                    <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px', mb: '20px' }}>
                        <span style={{ fontFamily: 'Poppins', color: COLORS.textColor }}>Note: </span>The appointment time is subject to last-minute change due to any unavoidable emergencies to be attended by the doctors.
                    </Typography>
                </DialogContent>

            </Dialog>
        </Box>
    );
};

export default RequestModal;
