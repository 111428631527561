import React, { createContext, useContext, useEffect, useState } from "react";
import Mixpanel from "mixpanel-browser";
import { token } from "./app";

const MixpanelContext = createContext();

export const useMixpanel = () => useContext(MixpanelContext);

export const MixpanelProvider = ({ children }) => {
    const [mixpanel, setMixpanel] = useState(null);

  useEffect(() => {
    const trackAutomaticEvents = true;
    const mixpanelInstance = new Mixpanel( token.production, trackAutomaticEvents);
    mixpanelInstance.init();
    setMixpanel(mixpanelInstance);
  }, []);

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {children}
    </MixpanelContext.Provider>
  );
};
